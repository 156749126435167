const DictionaryPT = {
  manageUsers: 'Gerenciar colaboradores',
  manageUsersSubtitle: 'Gerencie seus colaboradores',
  showing: 'Exibindo',
  of: 'De',
  loadMore: 'Carregar mais',
  loading: 'Carregando',
  searchByUserName: 'Pesquise pelo nome do colaborador',
  inviteEmployee: 'Convidar colaborador',
  requiredField: 'Campo obrigatório',
  invalidEmail: 'E-mail inválido',
  fillNewEmployeeDataTitle: 'Preencha os dados do novo colaborador',
  nameLabel: 'Nome',
  namePlaceholder: 'Informe o nome',
  lastNameLabel: 'Sobrenome',
  lastNamePlaceholder: 'Informe o sobrenome',
  emailPlaceholder: 'Informe o e-mail corporativo do colaborador',
  emailLabel: 'E-mail',
  sendInvite: 'Enviar convite',
  role: 'Função',
  inviteSended: 'Convite enviado com sucesso!',
  invalidDomain: 'Domínio inválido',
  user: 'Usuário',
  evaluator: 'Avaliador',
  delete: 'Excluir',
  areYouSureDeleteUser: 'Tem certeza de que deseja deletar esse usuário?',
  cannotBeUndoneAction: 'Essa ação não pode ser desfeita',
  noBack: 'Não, voltar',
  userDeleted: 'Usuário deletado com sucesso',
  statusNew: 'Novo',
  statusActive: 'Ativo',
  statusDraft: 'Rascunho',
  statusPending: 'Pendente',
  statusInvoiced: 'Faturado',
  resendActivationLink: 'Enviar link de ativação',
  activationLinkSended: 'Link de ativação enviado com sucesso',
  welcome: 'Seja bem vindo',
  activateAccountMessage: 'Para ativar sua conta você deve definir sua senha',
  passwordMustContainUppercase: 'Deve conter ao menos uma letra maiúscula',
  passwordMustContainNumber: 'Deve conter ao menos um número',
  passwordMustContainSpecialChar: 'Deve conter ao menos um caractere especial',
  passwordMustContainSixChars: 'Deve conter ao menos 6 caracteres',
  passwordPlaceholder: 'Digite sua senha',
  passwordLabel: 'Senha',
  confirmPasswordPlaceholder: 'Digite novamente sua senha',
  confirmPasswordLabel: 'Confirmação de senha',
  passwordDontMatch: 'Senhas não coincidem',
  invalidToken: 'Token inválido',
  activate: 'Ativar',
  userActivated: 'Usuário ativado com sucesso',
  redirecting: 'Redirecionando',
  notFoundData: 'Nenhum registro encontrado',
  leaving: 'Saindo',
  manageCompetencies: 'Gerenciar competências',
  manageCompetenciesSubtitle: 'Gerencie suas competências',
  searchByCompetenceName: 'Pesquisa pelo nome da competência',
  addCompetence: 'Adicionar competência',
  startAddingUser: 'Comece adicionando um novo usuário',
  startAddingCompetence: 'Comece adicionando uma nova competência',
  save: 'Salvar',
  fillNewCompetenceData: 'Preencha os dados da nova competência',
  competenceNamePTLabel: 'Nome da competência (PT)',
  competenceNamePTPlaceholder: 'Informe o nome a ser apresentado em Português',
  competenceNameENLabel: 'Nome da competência (EN)',
  competenceNameENPlaceholder: 'Informe o nome a ser apresentado em Inglês',
  competenceNameESLabel: 'Nome da competência (ES)',
  competenceNameESPlaceholder: 'Informe o nome a ser apresentado em Espanhol',
  competenceDescriptionPTLabel: 'Breve descritivo da competência (PT)',
  competenceDescriptionPTPlaceholder: 'Digite um resumo da competência a ser apresentado em Português',
  competenceDescriptionENLabel: 'Breve descritivo da competência (EN)',
  competenceDescriptionENPlaceholder: 'Digite um resumo da competência a ser apresentado em Inglês',
  competenceDescriptionESLabel: 'Breve descritivo da competência (ES)',
  competenceDescriptionESPlaceholder: 'Digite um resumo da competência a ser apresentado em Espanhol',
  next: 'Próximo',
  back: 'Voltar',
  saveDraft: 'Salvar rascunho',
  addCompetenceStep1Title: 'Informações básicas',
  addCompetenceStep2Title: 'Valores',
  addCompetenceStep3Title: 'Resumo',
  edit: 'Editar',
  publish: 'Publicar',
  draftSaved: 'Rascunho salvo',
  pleaseFillCurrentCompetenceValues: 'Por favor informe os dados da competência atual antes de adicionar uma nova',
  competence: 'Competência',
  values: 'Valores',
  competencePublished: 'Competência publicada com sucesso',
  manageAssessments: 'Gerenciar avaliações',
  manageAssessmentsSubtitle: 'Crie avaliações para seu time',
  createAssessment: 'Criar nova avaliação',
  searchByAssessmentName: 'Procurar pelo nome da avaliação',
  startAddingAssessment: 'Comece criando uma nova avaliação',
  addAssessmentStep1Title: 'Informações básicas',
  addAssessmentStep2Title: 'Competências',
  addAssessmentStep3Title: 'Colaboradores',
  addAssessmentStep4Title: 'Resumo',
  fillNewAssessmentData: 'Preencha os dados da nova avaliação',
  assessmentNamePTLabel: 'Nome da avaliação (PT)',
  assessmentNamePTPlaceholder: 'Nome que será apresentado em Português',
  assessmentNameENLabel: 'Nome da avaliação (EN)',
  assessmentNameENPlaceholder: 'Nome que será apresentado em Inglês',
  assessmentNameESLabel: 'Nome da avaliação (ES)',
  assessmentNameESPlaceholder: 'Nome que será apresentado em Espanhol',
  endDate: 'Data limite',
  informCompetences: 'Adicione as competências que irão compor a avaliação',
  confirm: 'Confirmar',
  selectCompetences: 'Selecione as competências',
  selectAll: 'Selecionar todos',
  informUsers: 'Adicione os usuários que poderão responder à pesquisa',
  addUser: 'Adicionar usuário',
  totalUsers: 'Total de usuários',
  totalCompetencies: 'Total de competências',
  selectUsers: 'Selecione os usuários',
  assessment: 'Avaliação',
  competencies: 'Competências',
  users: 'Usuários',
  assessmentPublished: 'Avaliação publicada com sucesso',
  myAssessments: 'Minhas avaliações',
  myAssessmentsSubtitle: 'Selecione uma das avaliações disponiveis',
  start: 'Iniciar',
  limiteDate: 'Data limite para envio',
  statusNotStarted: 'Não iniciado',
  statusInProgress: 'Em progresso',
  statusDone: 'Finalizado',
  continue: 'Continuar',
  noAssessmentFound: 'Nenhuma avaliação encontrada',
  selfEvaluation: 'Auto avaliação',
  reached: 'Atingido',
  expected: 'Esperado',
  priority: 'Prioridade',
  finish: 'Finalizar',
  backAssessments: 'Voltar para avaliações',
  view: 'Visualizar',
  managerEvaluation: 'Avaliação gestor',
  summary: 'Resumo',
  noNotifications: 'Nenhuma notificação encontrada',
  markAllAsRead: 'Marcar todas como lido',
  followLink: 'Seguir link',
  markAsRead: 'Marcar como lido',
  markAsUnread: 'Marcar como não lido',
  welcomeMessage: 'Olá, seja bem vindo',
  evaluate: 'Avaliar',
  areYouSuredeleteAssessment: 'Tem certeza de que deseja apagar essa avaliação?',
  allAnswersWillBeDeleted: 'Todas as respostas e o progresso dos usuários também serão apagados',
  selfEvaluationDone: 'Autoavaliação finalizada com sucesso',
  selfEvaluationDoneMessage: 'Uma notificação foi enviada para o seu gestor para que proceda com a próxima etapa.',
  managerEvaluationDone: 'Avaliação finalizada com sucesso',
  managerEvaluationDoneMessage: 'Uma notificação foi enviada para o colaborador',
  statusWaiting: 'Aguardando',
  evaluationDone: 'Avaliação finalizada',
  asssessmentDeleted: 'Avaliação removida com sucesso',
  usersWaitingEvaluation: 'Usuários aguardando avaliação',
  viewAll: 'Ver todos',
  employee: 'Colaborador',
  lastUpdate: 'Última atualização',
  status: 'Situação',
  showingLastSix: 'Exibindo os últimos 6 registros',
  waitingEvaluations: 'Aguardando avaliações',
  allowCertification: 'Permite certificação',
  yes: 'Sim',
  no: 'Não',
  competenceDeleted: 'Competência removida com sucesso',
  areYouSureDeleteCompetence: 'Tem certeza de que deseja apagar essa competência?',
  hasCertification: 'Possui certificação?',
  validity: 'Validade',
  pendingAssessments: 'Avaliações pendentes',
  billingInfo: 'Informações de faturamento',
  manageBillingInfo: 'Gerencie informações de faturamento',
  billingDate: 'Data faturamento',
  value: 'Valor',
  profile: 'Perfil',
  keepYourDataUpdated: 'Mantenha seus dados atualizados',
  dataUpdated: 'Dados atualizados',
  update: 'Atualizar',
  profileImageUpdated: 'Imagem de perfil atualizada',
  changeImage: 'Alterar imagem',
  failedToSendMail: 'Falha ao enviar e-mail',
  instructionsResetPasswordSendByEmail: 'Instruções para recuperação de senha enviada por e-mail',
  ok: 'OK',
  resetPasswordTitle: 'Insira seu email e enviaremos um link com os passos para redefinir sua senha',
  send: 'Enviar',
  informEmail: 'Informe o e-mail',
  setNewPasswordMessage: 'Siga as instruções abaixo para redefinir sua senha',
  reset: 'Redefinir',
  passwordReseted: 'Senha atualizada com sucesso',
  welcomeTitle: 'Seja bem-vindo à plataforma de Mapeamento de Competências',
  hello: 'Olá',
  loginSubtitle:
    'Identifique quais as aptidões técnicas e comportamentais dentro de sua empresa. Reconheça os  profissionais alinhados às expectativas e retenha talentos',
  forgotMyPassword: 'Esqueci minha senha',
  rememberAccess: 'Lembrar acesso',
  signIn: 'Entrar',
  authenticating: 'Autenticando',
  bannerText01: 'Mostre o seu potencial de',
  bannerText02: 'crescimento',
  bannerText03: 'Trabalhe seus pontos fracos e exiba suas',
  bannerText04: 'habilidades',
  bannerText05: 'Sua empresa possuí pessoas muito',
  bannerText06: 'talentosas',
  bannerText07: 'e nós o ajudaremos a',
  bannerText08: 'encontrá-las',
}

export default DictionaryPT
