import { CreateUserRequest, UpdateUserRequest, UserData } from '../models/user.model'
import axios from 'axios'
import { Pagination } from '../models/pagination.model'

export class UserService {
  async resetPassword(email: string): Promise<any> {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/user/reset-password`, { email: email })
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async setNewPassword(resetToken: string, password: string, confirmPassword: string): Promise<any> {
    const res = await axios.put(process.env.REACT_APP_API + `/user/set-new-password?resetToken=${resetToken}`, {
      password,
      confirmPassword,
    })

    const parsedResponse = await res.data
    return parsedResponse
  }

  async listUsersByTenant(
    tenandId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<UserData>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/user/tenant/${tenandId}/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      })

      const parsedResponse: Pagination<Array<UserData>> = await res.data

      parsedResponse.paginatedData = parsedResponse.paginatedData.map((user: UserData) => {
        return {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role,
          status: user.status,
          createdAt: user.createdAt,
        }
      })

      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async getUserDataById(_id: string): Promise<UserData> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/user/${_id}`)
      const parsedResponse: UserData = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async checkActivationToken(token: string): Promise<UserData> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/user/${token}/check-activation-token`)
      const parsedResponse: UserData = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async checkResetToken(token: string): Promise<UserData> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/user/${token}/check-reset-token`)
      const parsedResponse: UserData = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async sendInvite(userData: CreateUserRequest): Promise<UserData> {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/user`, userData)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async updateUser(_id: string, userData: UpdateUserRequest): Promise<UserData> {
    try {
      const res = await axios.put(process.env.REACT_APP_API + `/user/${_id}`, userData)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async changeUserImage(image: File, _id: string): Promise<UserData> {
    try {
      var formData = new FormData()
      formData.append('image', image)

      const res = await axios.put(process.env.REACT_APP_API + `/user/${_id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async deleteUser(id: string): Promise<UserData> {
    try {
      const res = await axios.delete(process.env.REACT_APP_API + `/user/${id}`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async activate(id: string, password: string): Promise<UserData> {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/user/${id}/activate`, { password })
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async sendActivationLink(id: string): Promise<UserData> {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/user/${id}/activation-link`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }
}
