import React from 'react'
import { AssessmentStore } from './assessments.store'
import { AuthStore } from './auth.store'
import { BillingStore } from './billing.store'
import { BreadcrumbStore } from './breadcrumb.store'
import { CompetenceStore } from './competence.store'
import { GeneralStore } from './general.store'
import { I18nStore } from './i18n.store'
import ModalStore from './modal.store'
import { NotificationStore } from './notification.store'
import { TenantStore } from './tenant.store'
import ThemeStore from './theme.store'
import { ToastStore } from './toast.store'
import { UserStore } from './user.store'

export interface IStoreContext {
  themeStore: ThemeStore
  modalStore: ModalStore
  toastStore: ToastStore
  authStore: AuthStore
  userStore: UserStore
  breadcrumbStore: BreadcrumbStore
  i18nStore: I18nStore
  tenantStore: TenantStore
  competenceStore: CompetenceStore
  generalStore: GeneralStore
  assessmentStore: AssessmentStore
  notificationStore: NotificationStore
  billingStore: BillingStore
}

const themeStore = new ThemeStore()
const modalStore = new ModalStore()
const toastStore = new ToastStore()
const i18nStore = new I18nStore()
const breadcrumbStore = new BreadcrumbStore()
const authStore = new AuthStore(i18nStore, toastStore)
const userStore = new UserStore(authStore, toastStore)
const tenantStore = new TenantStore(toastStore)
const competenceStore = new CompetenceStore(authStore, i18nStore, toastStore)
const generalStore = new GeneralStore(i18nStore)
const assessmentStore = new AssessmentStore(authStore, i18nStore, toastStore)
const notificationStore = new NotificationStore(authStore, i18nStore, toastStore)
const billingStore = new BillingStore(i18nStore, toastStore)

export const StoreContext = React.createContext<IStoreContext>({
  themeStore,
  modalStore,
  toastStore,
  breadcrumbStore,
  authStore,
  userStore,
  i18nStore,
  tenantStore,
  competenceStore,
  generalStore,
  assessmentStore,
  notificationStore,
  billingStore,
})
