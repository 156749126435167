import { Competence } from './competence.model'
import { Translations } from './i18n.model'
import { UserData } from './user.model'

export interface Assessment {
  _id: string
  name: Translations
  status: string
  endDate: Date | null
  tenant: string
  users: Array<{
    user: UserData
    progress: string
    step: string
    updatedAt?: Date
  }>
  competencies: Array<Competence>
  createdAt: Date
}

export class AssessmentForm {
  _id?: string
  name?: Translations
  status?: string
  endDate?: Date | null
  users?: Array<{ user: UserData; progress: string; step: string }>
  competencies?: Array<Competence>

  constructor() {
    this.name = {
      pt: '',
      en: '',
      es: '',
    }
    this.endDate = new Date()
    this.status = 'draft'
    this.users = []
    this.competencies = []
  }
}

export type AssessmentSteps = 'self_evaluation' | 'managers_evaluation' | 'evaluation_completed'

export class AssessmentEvaluationForm {
  assessment: string
  user: string
  currentCompetence: number
  currentProgress: number
  currentCompetenceManager: number
  currentProgressManager: number
  answers: Array<{
    competence: string
    competenceValues: [
      {
        [x: string]: any
        competenceValue: string
        reached: number
        certification: {
          hasCertification?: boolean | undefined
          expirationDate?: Date | undefined
        }
      }
    ]
  }>
  answersManager: Array<{
    competence: string
    competenceValues: [
      {
        [x: string]: any
        competenceValue: string
        expected: number
        reached: number
        priority: number
        sugestion?: string
      }
    ]
  }>

  constructor(assessment: string, user: string) {
    this.currentProgress = 0
    this.currentCompetence = 1
    this.currentProgressManager = 0
    this.currentCompetenceManager = 1
    this.assessment = assessment
    this.user = user
    this.answers = []
    this.answersManager = []
  }
}
