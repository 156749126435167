import axios from 'axios'
import { TenantData } from '../models/tenant.model'

export class TenantService {
  async getTenantById(id: string): Promise<TenantData> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/tenant/${id}`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }
}
