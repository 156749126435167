const DictionaryEN = {
  manageUsers: 'Manage users',
  manageUsersSubtitle: 'Manage your users',
  showing: 'Showing',
  of: 'Of',
  loadMore: 'Load more',
  loading: 'Loading',
  searchByUserName: 'Search by employee name',
  inviteEmployee: 'Invite employee',
  requiredField: 'Required field',
  invalidEmail: 'Invalid email',
  fillNewEmployeeDataTitle: 'Fill in the details of the new employee',
  nameLabel: 'Name',
  namePlaceholder: 'Enter first name',
  lastNameLabel: 'Last name',
  lastNamePlaceholder: 'Enter the last name',
  emailPlaceholder: "Enter the employee's corporate email",
  emailLabel: 'Email',
  sendInvite: 'Send invite',
  role: 'Role',
  inviteSended: 'Invitation sent successfully!',
  invalidDomain: 'Invalid domain',
  user: 'User',
  evaluator: 'Evaluator',
  delete: 'Delete',
  areYouSureDeleteUser: 'Are you sure you want to delete this user?',
  cannotBeUndoneAction: 'This action cannot be undone.',
  noBack: 'No, back',
  userDeleted: 'User deleted successfully',
  statusNew: 'New',
  statusActive: 'Active',
  statusDraft: 'Draft',
  statusPending: 'Pending',
  statusInvoiced: 'Invoiced',
  resendActivationLink: 'Send activation link',
  activationLinkSended: 'Activation link sent with success',
  welcome: 'Welcome',
  activateAccountMessage: 'To activate your account you must set your password',
  passwordMustContainUppercase: 'Must contain at least one uppercase letter',
  passwordMustContainNumber: 'Must contain at least one number',
  passwordMustContainSpecialChar: 'Must contain at least one special character',
  passwordMustContainSixChars: 'Must contain at least 6 characters',
  passwordPlaceholder: 'Enter your password',
  passwordLabel: 'Password',
  confirmPasswordPlaceholder: 'Retype your password',
  confirmPasswordLabel: 'Password confirmation',
  passwordDontMatch: "Password Don't Match",
  invalidToken: 'Invalid token',
  activate: 'Activate',
  userActivated: 'User activated successfully',
  redirecting: 'Redirecting',
  notFoundData: 'No data found',
  leaving: 'Leaving',
  manageCompetencies: 'Manage competencies',
  manageCompetenciesSubtitle: 'Manage your competencies',
  searchByCompetenceName: 'Search by competence name',
  addCompetence: 'Add competence',
  startAddingUser: 'Start adding a new user',
  startAddingCompetence: 'Start by adding a new competence',
  save: 'Save',
  fillNewCompetenceData: 'Fill in the new competence data',
  competenceNamePTLabel: 'Competence name (PT)',
  competenceNamePTPlaceholder: 'Enter the name to be displayed in Portuguese',
  competenceNameENLabel: 'Competence name (EN)',
  competenceNameENPlaceholder: 'Enter the name to be displayed in English',
  competenceNameESLabel: 'Competence Name (ES)',
  competenceNameESPlaceholder: 'Enter the name to be displayed in Spanish',
  competenceDescriptionPTLabel: 'Short description of competence (PT)',
  competenceDescriptionPTPlaceholder: 'Type a competency summary to be presented in Portuguese',
  competenceDescriptionENLabel: 'Short description of competence (EN)',
  competenceDescriptionENPlaceholder: 'Type a competency summary to be presented in English',
  competenceDescriptionESLabel: 'Short competence description (ES)',
  competenceDescriptionESPlaceholder: 'Type a competency summary to be presented in Spanish',
  next: 'Next',
  back: 'Back',
  saveDraft: 'Save draft',
  addCompetenceStep1Title: 'Basic info',
  addCompetenceStep2Title: 'Values',
  addCompetenceStep3Title: 'Summary',
  edit: 'Edit',
  publish: 'Publish',
  draftSaved: 'Draft saved',
  pleaseFillCurrentCompetenceValues: 'Please enter current competence data before adding a new one',
  competence: 'Competence',
  values: 'Values',
  competencePublished: 'Competence successfully published',
  manageAssessments: 'Manage assessments',
  manageAssessmentsSubtitle: 'Create assessments for your team',
  createAssessment: 'Create new assessment',
  searchByAssessmentName: 'Search by assessment name',
  startAddingAssessment: 'Start by creating a new assessment',
  addAssessmentStep1Title: 'Basic Information',
  addAssessmentStep2Title: 'Competences',
  addAssessmentStep3Title: 'Employes',
  addAssessmentStep4Title: 'Summary',
  fillNewAssessmentData: 'Fill in the new assessment data',
  assessmentNamePTLabel: 'Assessment name (PT)',
  assessmentNamePTPlaceholder: 'Name that will be presented in Portuguese',
  assessmentNameENLabel: 'Assessment name (EN)',
  assessmentNameENPlaceholder: 'Name that will be displayed in English',
  assessmentNameESLabel: 'Assessment name (ES)',
  assessmentNameESPlaceholder: 'Name that will be presented in Spanish',
  endDate: 'End date',
  informCompetences: 'Add the competences that will compose the assessment',
  confirm: 'Confirm',
  selectCompetences: 'Select competencies',
  selectAll: 'Select all',
  informUsers: 'Add users who will be able to respond to the survey',
  addUser: 'Add user',
  totalUsers: 'Total users',
  totalCompetencies: 'Total competencies',
  selectUsers: 'Select users',
  assessment: 'Assessment',
  competencies: 'Competencies',
  users: 'Users',
  assessmentPublished: 'Assessment successfully published',
  myAssessments: 'My assessments',
  myAssessmentsSubtitle: 'Select one of the available assessments',
  start: 'Start',
  limiteDate: 'Deadline date for sending',
  statusNotStarted: 'Not started',
  statusInProgress: 'In progress',
  statusDone: 'Done',
  continue: 'Continue',
  noAssessmentFound: 'No assessment found',
  selfEvaluation: 'Self evaluation',
  reached: 'Reached',
  expected: 'Expected',
  priority: 'Priority',
  finish: 'Finish',
  backAssessments: 'Back to Assessments',
  view: 'View',
  managerEvaluation: 'Manager evaluation',
  summary: 'Summary',
  noNotifications: 'No notifications found',
  markAllAsRead: 'Mark all as read',
  followLink: 'Follow link',
  markAsRead: 'Mark as read',
  markAsUnread: 'Mark as unread',
  welcomeMessage: 'Hello, welcome',
  evaluate: 'Evaluate',
  areYouSuredeleteAssessment: 'Are you sure you want to delete this assessment?',
  allAnswersWillBeDeleted: 'All responses and user progress will also be deleted',
  selfEvaluationDone: 'Self-assessment completed successfully',
  selfEvaluationDoneMessage: 'A notification has been sent to your manager to proceed with the next step',
  managerEvaluationDone: 'Evaluation completed successfully',
  managerEvaluationDoneMessage: 'A notification has been sent to the employee',
  statusWaiting: 'Waiting',
  evaluationDone: 'Assessment done',
  asssessmentDeleted: 'Assessment successfully removed',
  usersWaitingEvaluation: 'Users waiting for evaluation',
  viewAll: 'View all',
  employee: 'Employee',
  lastUpdate: 'Last update',
  status: 'Status',
  showingLastSix: 'Displaying the last 6 records',
  waitingEvaluations: 'Waiting for evaluations',
  allowCertification: 'Allow certification',
  yes: 'Yes',
  no: 'No',
  competenceDeleted: 'Competence successfully removed',
  areYouSureDeleteCompetence: 'Are you sure you want to delete this competency?',
  hasCertification: 'Has certification?',
  validity: 'Validity',
  pendingAssessments: 'Pending assessments',
  billingInfo: 'Billing Information',
  manageBillingInfo: 'Manage billing information',
  billingDate: 'Billing date',
  value: 'Value',
  profile: 'Profile',
  keepYourDataUpdated: 'Keep your data up to date',
  dataUpdated: 'Data updated',
  update: 'Update',
  profileImageUpdated: 'Profile image updated',
  changeImage: 'Change image',
  failedToSendMail: 'Failed to send email',
  instructionsResetPasswordSendByEmail: 'Password recovery instructions sent by email',
  ok: 'OK',
  resetPasswordTitle: "Enter your email and we'll send you a link with steps to reset your password",
  send: 'Send',
  informEmail: 'Enter the email',
  setNewPasswordMessage: 'Follow the instructions below to reset your password',
  reset: 'Reset',
  passwordReseted: 'Password updated successfully',
  welcomeTitle: 'Welcome to the Competency Mapping platform',
  hello: 'Hello',
  loginSubtitle:
    'Identify the technical and behavioral skills within your company. Recognize professionals aligned with expectations and retain talent',
  forgotMyPassword: 'Forgot my password',
  rememberAccess: 'Remember Access',
  signIn: 'Sign in',
  authenticating: 'Authenticating',
  bannerText01: 'Show your potential',
  bannerText02: 'growth',
  bannerText03: 'Work your weaknesses and show off yours',
  bannerText04: 'skills',
  bannerText05: 'Your company has very',
  bannerText06: 'talented',
  bannerText07: 'people and we will help you',
  bannerText08: 'find them',
}

export default DictionaryEN
