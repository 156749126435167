import { Grid, Typography, Stack, TextField, FormHelperText, MenuItem } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import ButtonLoader from '../../../components/button-loader'
import * as Yup from 'yup'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../../stores/store.context'
import { Roles } from '../../../models/roles.model'
import { CreateUserRequest } from '../../../models/user.model'
import { trackPromise } from 'react-promise-tracker'
import { RolesEnum } from '../../../enums/roles.enum'

const InviteUserForm: React.FC = () => {
  const { tenantStore, i18nStore, userStore, toastStore, modalStore, authStore } = useContext(StoreContext)

  useEffect(() => {
    const getTenant = async () => {
      if (authStore.tokenData) {
        await tenantStore.getTenantById(authStore.tokenData.tenant)
        if (!tenantStore.tenant) {
          modalStore.handleClose()
        }
      }
    }

    getTenant()
  })

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        role: '' as Roles,
      }}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(i18nStore.dictionary.requiredField),
        lastName: Yup.string().required(i18nStore.dictionary.requiredField),
        email: Yup.string()
          .email(i18nStore.dictionary.invalidEmail)
          .required(i18nStore.dictionary.requiredField)
          .test('test-domain', i18nStore.dictionary.invalidDomain, (value) => {
            if (tenantStore.tenant && value) {
              value = value.split('@')[1]
              if (tenantStore.tenant.domains.indexOf(value) === -1) {
                return false
              }
            }

            return true
          }),
        role: Yup.string().required(i18nStore.dictionary.requiredField),
      })}
      onSubmit={async (values) => {
        const userData: CreateUserRequest = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
        }

        if (authStore.tokenData!.role === RolesEnum.evaluator) {
          userData.evaluator = authStore.tokenData!._id
        }

        const userCreated = await trackPromise(userStore.sendInvite(userData), 'invite-user')

        if (userCreated) {
          toastStore.pushToast({
            message: i18nStore.dictionary.inviteSended,
            type: 'success',
          })

          if (authStore.tokenData) {
            await userStore.listUsersByTenant(
              authStore.tokenData.tenant,
              parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
              parseInt(process.env.REACT_APP_DATA_LIMIT as string)
            )
          }

          modalStore.handleClose()
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isValid, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h4">{i18nStore.dictionary.fillNewEmployeeDataTitle}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  fullWidth
                  error={Boolean(touched.firstName && errors.firstName)}
                  type="firstName"
                  value={values.firstName}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={i18nStore.dictionary.namePlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.nameLabel}
                  inputProps={{
                    autoComplete: 'user-firstName',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.firstName && errors.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  fullWidth
                  error={Boolean(touched.lastName && errors.lastName)}
                  type="lastName"
                  value={values.lastName}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={i18nStore.dictionary.lastNamePlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.lastNameLabel}
                  inputProps={{
                    autoComplete: 'user-lastName',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.lastName && errors.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={i18nStore.dictionary.emailPlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.emailLabel}
                  inputProps={{
                    autoComplete: 'user-email',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                error={Boolean(touched.role && errors.role)}
                type="role"
                value={values.role}
                name="role"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={i18nStore.dictionary.role}
                variant="outlined"
                label={i18nStore.dictionary.role}
                inputProps={{
                  autoComplete: 'user-role',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              >
                {authStore.tokenData!.role === RolesEnum.evaluator && (
                  <MenuItem value={'user'}>{i18nStore.dictionary.user}</MenuItem>
                )}
                {authStore.tokenData!.role === RolesEnum.master && (
                  <MenuItem value={'evaluator'}>{i18nStore.dictionary.evaluator}</MenuItem>
                )}
              </TextField>
              {touched.role && errors.role && <FormHelperText error>{errors.role}</FormHelperText>}
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <ButtonLoader
                loadingText={i18nStore.dictionary.loading}
                text={i18nStore.dictionary.sendInvite}
                area="invite-user"
                disabled={!isValid}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default observer(InviteUserForm)
