import { createBrowserRouter } from 'react-router-dom'
import AuthenticatedLayout from '../layout/authenticated-layout'
import DashboardPage from '../pages/dashboard'
import LoginPage from '../pages/login'
import NotFoundPage from '../pages/not-found'
import ManageUsersPage from '../pages/manage-users'
import BillingPage from '../pages/billing'
import ManageCompetenciesPage from '../pages/manage-competencies'
import '../request.interceptor'
import UserActivation from '../pages/user/user-activation'
import NotAuthenticatedLayout from '../layout/not-authenticated-layout'
import ManageAssessmentsPage from '../pages/manage-assessments'
import AssessmentMainPage from '../pages/assessment'
import AssessmentEvaluateUserPage from '../pages/assessment/assessment-evaluate-user'
import AssessmentSummaryPage from '../pages/assessment/assessment-summary'
import UserProfilePage from '../pages/user/user-profile'
import UserSetNewPassword from '../pages/user/user-set-new-password'

const Router = createBrowserRouter([
  {
    path: '/secure',
    element: <AuthenticatedLayout />,
    children: [
      {
        index: true,
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'billing',
        element: <BillingPage />,
      },
      {
        path: 'user',
        children: [{ index: true, path: 'profile', element: <UserProfilePage /> }],
      },
      {
        path: 'assessment',
        children: [
          { index: true, path: '', element: <AssessmentMainPage /> },
          { path: ':id/user/:user/evaluate', element: <AssessmentEvaluateUserPage /> },
          { path: ':id/summary', element: <AssessmentSummaryPage /> },
        ],
      },
      {
        path: 'manage-users',
        children: [
          {
            index: true,
            path: '',
            element: <ManageUsersPage />,
          },
        ],
      },
      {
        path: 'manage-competencies',
        children: [
          {
            index: true,
            path: '',
            element: <ManageCompetenciesPage />,
          },
        ],
      },
      {
        path: 'manage-assessments',
        children: [
          {
            index: true,
            path: '',
            element: <ManageAssessmentsPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <NotAuthenticatedLayout />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/user/reset-password',
        element: <UserSetNewPassword />,
      },
      {
        path: '/user/activation/:activationHash',
        element: <UserActivation />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

export default Router
