import { Avatar, Divider, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'

interface AssessmentFormStep4Props {
  step: number
}

const AssessmentFormStep4: React.FC<AssessmentFormStep4Props> = (props: AssessmentFormStep4Props) => {
  const { assessmentStore, i18nStore, userStore } = useContext(StoreContext)

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h2">{i18nStore.dictionary.addAssessmentStep4Title}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Typography variant="h4">{i18nStore.dictionary.assessment}:</Typography>
        <Typography variant="h5" sx={{ ml: 2 }}>
          {assessmentStore.assessmentFormData!.name![i18nStore.language]}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {assessmentStore.assessmentFormData && assessmentStore.assessmentFormData.competencies && (
          <Grid container spacing={1} sx={{ mt: 2, overflow: 'auto', maxHeight: '50vh' }}>
            <Grid item xs={12}>
              <Typography variant="h4">{i18nStore.dictionary.competencies}:</Typography>
            </Grid>
            {assessmentStore.assessmentFormData.competencies.map((competence, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Typography variant="h5">{competence.name[i18nStore.language]}</Typography>
                </Grid>
              )
            })}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {assessmentStore.assessmentFormData && assessmentStore.assessmentFormData.users && (
          <Grid container spacing={1} sx={{ mt: 2, overflow: 'auto', maxHeight: '50vh' }}>
            <Grid item xs={12}>
              <Typography variant="h4">{i18nStore.dictionary.users}:</Typography>
            </Grid>
            {assessmentStore.assessmentFormData.users.map((assessment, index) => {
              return (
                <Grid item xs={6} sm={4} key={index}>
                  <Fragment key={index}>
                    <ListItem disablePadding sx={{ '&:not(:first-of-type)': { mt: 2 } }} alignItems="center">
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'secondary.main' }}>
                          {userStore.getUserInitials(assessment.user.firstName, assessment.user.lastName)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={assessment.user.firstName + ' ' + assessment.user.lastName} />
                    </ListItem>
                  </Fragment>
                </Grid>
              )
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(AssessmentFormStep4)
