/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  Grid,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  styled,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AssessmentSteps } from '../../models/assessment.model'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 2px 5px -1px ${theme.palette.grey[100]}, 0px 1px 5px 0px ${theme.palette.grey[500]}, 0px 1px 15px 0px ${theme.palette.grey[400]} !important`,
}))

interface AssessmentSummaryAccordionsProps {
  steps: any
  getUsersInStep: Function
}

const AssessmentSummaryAccordions: React.FC<AssessmentSummaryAccordionsProps> = observer(
  (props: AssessmentSummaryAccordionsProps) => {
    const { assessmentStore } = useContext(StoreContext)

    const navigate = useNavigate()

    return (
      <Fragment>
        {props.steps.map((accordion: any, i: number) => {
          return (
            <StyledAccordion key={i} sx={{ boxShadow: 1, '&:not(:first-of-type)': { mt: 2 } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1">{accordion.text}</Typography>
                  <Chip sx={{ ml: 2 }} label={props.getUsersInStep(accordion.status)} color="primary" size="small" />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {assessmentStore.assessment &&
                  assessmentStore.assessment.users.map((assessment, j) => {
                    if (assessment.progress === accordion.status) {
                      return (
                        <Box key={j} sx={{ display: 'flex', alignItems: 'center', '&:not(:first-of-type)': { mt: 2 } }}>
                          <Typography variant="h6">
                            {assessment.user.firstName + ' ' + assessment.user.lastName}
                          </Typography>
                          <Chip
                            sx={{ ml: 2 }}
                            label={assessmentStore.getStepLabel(assessment.step as AssessmentSteps)}
                            color="warning"
                            size="small"
                          />
                          {assessment.step === 'managers_evaluation' && (
                            <IconButton
                              sx={{ ml: 2 }}
                              onClick={() =>
                                navigate(
                                  `/secure/assessment/${assessmentStore.assessment!._id}/user/${
                                    assessment.user._id
                                  }/evaluate?edit=true&step=managers_evaluation`
                                )
                              }
                            >
                              <OpenInNewIcon sx={{ color: 'text.primary' }} />
                            </IconButton>
                          )}
                          {assessment.step === 'evaluation_completed' && (
                            <IconButton
                              sx={{ ml: 2 }}
                              onClick={() =>
                                navigate(
                                  `/secure/assessment/${assessmentStore.assessment!._id}/user/${
                                    assessment.user._id
                                  }/evaluate?view=true&step=evaluation_completed`
                                )
                              }
                            >
                              <VisibilityIcon sx={{ color: 'text.primary' }} />
                            </IconButton>
                          )}
                        </Box>
                      )
                    }
                    return <Fragment key={j}></Fragment>
                  })}
              </AccordionDetails>
            </StyledAccordion>
          )
        })}
      </Fragment>
    )
  }
)

const AssessmentSummaryPage: React.FC = () => {
  const { i18nStore, assessmentStore, breadcrumbStore, authStore } = useContext(StoreContext)
  const [chartData, setChartData] = useState([0, 0, 0, 0])
  const params = useParams()

  const steps: Array<{ text: string; status: string }> = [
    { text: i18nStore.dictionary.statusNotStarted, status: 'not_started' },
    { text: i18nStore.dictionary.statusInProgress, status: 'in_progress' },
    { text: i18nStore.dictionary.statusWaiting, status: 'waiting' },
    { text: i18nStore.dictionary.statusDone, status: 'done' },
  ]

  const getUsersInStep = (step: string) => {
    let amount = 0

    if (assessmentStore.assessment) {
      assessmentStore.assessment.users.forEach((assessment) => {
        if (assessment.progress === step) {
          amount = amount + 1
        }
      })
    }

    return amount
  }

  const data = {
    labels: [
      i18nStore.dictionary.statusNotStarted,
      i18nStore.dictionary.statusInProgress,
      i18nStore.dictionary.statusWaiting,
      i18nStore.dictionary.statusDone,
    ],
    datasets: [
      {
        label: '# ' + i18nStore.dictionary.users,
        data: chartData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  }

  useEffect(() => {
    const getById = async (id: string) => {
      await assessmentStore.getAssessmentData(id)

      steps.forEach((step, i) => {
        const total = getUsersInStep(step.status)
        setChartData((prevValue) => {
          prevValue[i] = total

          return prevValue
        })
      })

      if (assessmentStore.assessment && params.id) {
        breadcrumbStore.setBreadcrumbItems([
          {
            name: i18nStore.dictionary.manageAssessments,
            link: '/secure/manage-assessments',
          },
          {
            name: assessmentStore.assessment.name[i18nStore.language],
            link: `/secure/assessment/${assessmentStore.assessment._id}/summary`,
          },
        ])
      }
    }

    if (params.id) {
      getById(params.id)
    }
  }, [assessmentStore, params.id, i18nStore.language, breadcrumbStore, authStore.tokenData])

  return (
    assessmentStore.assessment && (
      <Fragment>
        <Box>
          <BreadCrumb />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h2">{assessmentStore.assessment.name[i18nStore.language]}</Typography>
          </Box>

          <Typography variant="body2" sx={{ mt: 2 }}>
            {i18nStore.dictionary.limiteDate}:{' '}
            {new Date(assessmentStore.assessment.endDate!).toLocaleDateString(i18nStore.locale)}
          </Typography>
        </Box>

        <ContentArea>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <AssessmentSummaryAccordions steps={steps} getUsersInStep={getUsersInStep} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Radar data={data} />
            </Grid>
          </Grid>
        </ContentArea>
      </Fragment>
    )
  )
}

export default observer(AssessmentSummaryPage)
