import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../stores/store.context'
import { BreadcrumbItem } from '../models/breadcrumb.model'
import { Box, Divider, Link, Typography } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom'

const BreadCrumb: React.FC = () => {
  const { breadcrumbStore } = useContext(StoreContext)
  const breadcrumbItems = breadcrumbStore.getBreadcrumb()

  return (
    <>
      <Box sx={{ my: 5 }}>
        <Box sx={{ display: 'flex' }}>
          <Link underline="none" component={RouterLink} to="/secure/dashboard">
            <Typography variant="subtitle2" color="primary">
              Home
              <Box component="span" sx={{ mx: 1 }}>
                &gt;
              </Box>
            </Typography>
          </Link>
          {breadcrumbItems.map((breadcrumb: BreadcrumbItem, index: number) => {
            if (breadcrumbItems.length - 1 === index || !breadcrumb.link) {
              return (
                <Typography key={index} variant="body2" color="secondary.lighter">
                  {breadcrumb.name}
                </Typography>
              )
            } else {
              return (
                <Link key={index} underline="none" component={RouterLink} to={breadcrumb.link}>
                  <Typography variant="subtitle2" color="primary">
                    {breadcrumb.name}{' '}
                    {breadcrumbItems.length > 1 ? (
                      <Box component="span" sx={{ mx: 1 }}>
                        &gt;
                      </Box>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Link>
              )
            }
          })}
        </Box>
        <Divider sx={{ my: 2 }} />
      </Box>
    </>
  )
}

export default observer(BreadCrumb)
