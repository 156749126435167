import { Translations } from './i18n.model'
import { UserData } from './user.model'

export interface CompetenceValue {
  _id?: string
  name: Translations
  description: Translations
  showMore?: boolean
  isValid?: boolean
  allowCertification?: boolean
}

export interface Competence {
  _id: string
  name: Translations
  description: Translations
  tenant: string
  status: string
  competenceValues: Array<CompetenceValue>
  createdBy: UserData
  createdAt: Date
  checked?: boolean
}

export class CompetenceForm {
  _id?: string
  name?: Translations
  description?: Translations
  status?: string
  competenceValues?: Array<CompetenceValue>

  constructor() {
    this.name = {
      pt: '',
      en: '',
      es: '',
    }
    this.description = {
      pt: '',
      en: '',
      es: '',
    }
    this.status = 'draft'
    this.competenceValues = []
  }
}
