import { Box, Container } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import PageLoader from '../components/page-loader'
import Forbidden from '../pages/forbidden'
import { StoreContext } from '../stores/store.context'

const AuthenticatedLayout: React.FC = () => {
  const { authStore } = useContext(StoreContext)
  const authenticated = authStore.authenticated
  const currentPath = useLocation().pathname

  useEffect(() => {
    // const checkAccess = async () => await authStore.validateAccess(authStore.tokenData!.role, currentPath)
    const getMenu = async () => await authStore.getMenu(authStore.tokenData!.role)

    if (authStore.tokenData) {
      getMenu()

      // if (typeof authStore.hasAccess === 'undefined') {
      //   checkAccess()
      // } else {
      //   if (!authStore.hasAccess) {
      //     authStore.logout()
      //   } else {
      //   }
      // }
    }
  }, [authStore, currentPath, authStore.hasAccess])

  return (
    <>
      {authenticated ? (
        <>
          <PageLoader />
          <Navbar />
          <Container maxWidth="md">
            <Box sx={{ mt: 5 }}>
              <Outlet />
            </Box>
          </Container>
        </>
      ) : (
        <>
          <Forbidden />
        </>
      )}

      <Footer />
    </>
  )
}

export default observer(AuthenticatedLayout)
