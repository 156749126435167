import { Menu, MenuList, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { StoreContext } from '../../../stores/store.context'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { Assessment } from '../../../models/assessment.model'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ModalOpts } from '../../../models/modal.model'
import AssessmentForm from '../forms/assessment-form'
import { useNavigate } from 'react-router-dom'
import DeleteAssessmentModal from '../modals/delete-assessment.modal'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

interface AssessmentMenuProps {
  assessment: Assessment
}

const AssessmentMenu: React.FC<AssessmentMenuProps> = (props: AssessmentMenuProps) => {
  const { i18nStore, modalStore } = useContext(StoreContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const navigate = useNavigate()

  return (
    <Fragment>
      <IconButton color="primary" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{
          width: 320,
          maxWidth: '100%',
          '& .MuiPaper-root': {
            backgroundColor: 'background.default',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList>
          {props.assessment.status === 'active' && (
            <MenuItem
              onClick={() => {
                navigate(`/secure/assessment/${props.assessment._id}/summary`)
              }}
            >
              <ListItemIcon>
                <AssessmentIcon fontSize="small" sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              <ListItemText>{i18nStore.dictionary.summary}</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              const modalOpts: ModalOpts = {
                size: 'lg',
              }
              modalStore.openModal(<AssessmentForm _id={props.assessment._id} />, modalOpts)
              handleClose()
            }}
          >
            {props.assessment.status !== 'active' ? (
              <Fragment>
                <ListItemIcon>
                  <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </ListItemIcon>
                <ListItemText>{i18nStore.dictionary.edit}</ListItemText>
              </Fragment>
            ) : (
              <Fragment>
                <ListItemIcon>
                  <RemoveRedEyeIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </ListItemIcon>
                <ListItemText>{i18nStore.dictionary.view}</ListItemText>
              </Fragment>
            )}
          </MenuItem>

          <MenuItem
            onClick={() => {
              modalStore.openModal(<DeleteAssessmentModal id={props.assessment._id} />, { size: 'sm' })
              handleClose()
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <ListItemText>{i18nStore.dictionary.delete}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Fragment>
  )
}

export default observer(AssessmentMenu)
