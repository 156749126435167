import { Box, Typography, Grid, FormHelperText, Stack, TextField, Avatar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import { Formik } from 'formik'
import { trackPromise } from 'react-promise-tracker'
import ButtonLoader from '../../components/button-loader'
import * as Yup from 'yup'
import { UpdateUserRequest } from '../../models/user.model'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'

const UserProfilePage: React.FC = () => {
  const { i18nStore, breadcrumbStore, authStore, userStore, toastStore } = useContext(StoreContext)
  const [selectedImage, setSelectedImage] = useState()

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.profile,
        link: '/secure/user/profile',
      },
    ])

    const loadUserData = async () => {
      if (authStore.tokenData) {
        await userStore.getUserDataById(authStore.tokenData._id)

        if (userStore.user) {
          if (userStore.user.image) {
          }
        }
      }
    }

    loadUserData()
  }, [authStore.tokenData, breadcrumbStore, i18nStore.dictionary.profile, userStore])

  const imageChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0])
      const file = e.target.files[0]
      if (userStore.user) {
        const logoChanged = await trackPromise(userStore.changeUserImage(file, userStore.user._id), 'change-image')

        if (logoChanged) {
          toastStore.pushToast({ message: i18nStore.dictionary.profileImageUpdated, type: 'success' })
        }
      }
    }
  }

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{userStore.user && userStore.userFullName}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.keepYourDataUpdated}
        </Typography>
      </Box>

      <ContentArea>
        {userStore.user && (
          <Formik
            initialValues={{
              firstName: userStore.user.firstName ?? '',
              lastName: userStore.user.lastName ?? '',
              email: userStore.user.email ?? '',
            }}
            validateOnMount={true}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required(i18nStore.dictionary.requiredField),
              lastName: Yup.string().required(i18nStore.dictionary.requiredField),
              email: Yup.string().email(i18nStore.dictionary.invalidEmail).required(i18nStore.dictionary.requiredField),
            })}
            onSubmit={async (values) => {
              const userData: UpdateUserRequest = {
                firstName: values.firstName,
                lastName: values.lastName,
              }

              const userUpdated = await trackPromise(
                userStore.updateUser(authStore.tokenData!._id, userData),
                'update-user'
              )

              if (userUpdated) {
                toastStore.pushToast({
                  message: i18nStore.dictionary.dataUpdated,
                  type: 'success',
                })
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isValid, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container display="flex" spacing={3}>
                  <Grid item xs={4}>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ height: '100%' }}
                    >
                      {userStore.user && !userStore.user.image && !selectedImage ? (
                        <Avatar
                          sx={{
                            backgroundColor: 'secondary.main',
                            width: '200px',
                            height: '200px',
                            fontSize: '4.5rem',
                          }}
                        >
                          {userStore.getUserInitials(values.firstName, values.lastName)}
                        </Avatar>
                      ) : (
                        <Box
                          sx={{
                            objectFit: 'cover',
                            width: '200px',
                            height: '200px',
                            borderRadius: '50%',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'grey.300',
                          }}
                          component="img"
                          src={
                            !selectedImage
                              ? process.env.REACT_APP_API + '/uploads/user/' + userStore.user!.image
                              : URL.createObjectURL(selectedImage)
                          }
                        />
                      )}

                      <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
                        <ButtonLoader
                          component="label"
                          variant="outlined"
                          loadingText={i18nStore.dictionary.loading}
                          text={
                            <>
                              <FileUploadOutlinedIcon sx={{ mr: 2 }} /> {i18nStore.dictionary.changeImage}
                              <input onChange={imageChange} hidden accept="image/*" type="file" />
                            </>
                          }
                          area="change-image"
                        />
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.firstName && errors.firstName)}
                            type="firstName"
                            value={values.firstName}
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={i18nStore.dictionary.namePlaceholder}
                            variant="outlined"
                            label={i18nStore.dictionary.nameLabel}
                            inputProps={{
                              autoComplete: 'user-firstName',
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />
                          {touched.firstName && errors.firstName && (
                            <FormHelperText error>{errors.firstName}</FormHelperText>
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.lastName && errors.lastName)}
                            type="lastName"
                            value={values.lastName}
                            name="lastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={i18nStore.dictionary.lastNamePlaceholder}
                            variant="outlined"
                            label={i18nStore.dictionary.lastNameLabel}
                            inputProps={{
                              autoComplete: 'user-lastName',
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />
                          {touched.lastName && errors.lastName && (
                            <FormHelperText error>{errors.lastName}</FormHelperText>
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.email && errors.email)}
                            type="email"
                            value={values.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled
                            placeholder={i18nStore.dictionary.emailPlaceholder}
                            variant="outlined"
                            label={i18nStore.dictionary.emailLabel}
                            inputProps={{
                              autoComplete: 'user-email',
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />
                          {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                        </Stack>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                      <ButtonLoader
                        loadingText={i18nStore.dictionary.loading}
                        text={i18nStore.dictionary.update}
                        area="update-user"
                        disabled={!isValid}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
      </ContentArea>
    </Fragment>
  )
}

export default observer(UserProfilePage)
