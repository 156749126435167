import { Grid, Stack, TextField, FormHelperText } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'
import { useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'

interface AddCompetenceStep1Props {
  step: number
}

const AddCompetenceStep1: React.FC<AddCompetenceStep1Props> = (props: AddCompetenceStep1Props) => {
  const { i18nStore, competenceStore } = useContext(StoreContext)

  const formSchema = Yup.object().shape({
    namePT: Yup.string().required(i18nStore.dictionary.requiredField),
    nameEN: Yup.string().required(i18nStore.dictionary.requiredField),
    nameES: Yup.string().required(i18nStore.dictionary.requiredField),
    descriptionPT: Yup.string().required(i18nStore.dictionary.requiredField),
    descriptionEN: Yup.string().required(i18nStore.dictionary.requiredField),
    descriptionES: Yup.string().required(i18nStore.dictionary.requiredField),
  })

  return (
    <Formik
      initialValues={{
        namePT: competenceStore.competenceFormData!.name!.pt,
        nameEN: competenceStore.competenceFormData!.name!.en,
        nameES: competenceStore.competenceFormData!.name!.es,
        descriptionPT: competenceStore.competenceFormData!.description!.pt,
        descriptionEN: competenceStore.competenceFormData!.description!.en,
        descriptionES: competenceStore.competenceFormData!.description!.es,
      }}
      validationSchema={formSchema}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={() => {}}
    >
      {({ errors, handleBlur, handleChange, touched, isValid }) => {
        return (
          <form noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.namePT && errors.namePT)}
                    type="text"
                    value={competenceStore.competenceFormData!.name!.pt}
                    name="namePT"
                    onBlur={handleBlur}
                    onChange={async (e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      competenceStore.competenceFormData = {
                        ...competenceStore.competenceFormData,
                        name: {
                          ...competenceStore.competenceFormData!.name,
                          pt: newValue,
                        },
                      }
                      competenceStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.competenceNamePTPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.competenceNamePTLabel}
                    inputProps={{
                      autoComplete: 'competence-name-pt',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.namePT && errors.namePT && <FormHelperText error>{errors.namePT}</FormHelperText>}
                </Stack>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.nameEN && errors.nameEN)}
                    type="text"
                    value={competenceStore.competenceFormData!.name!.en}
                    name="nameEN"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      competenceStore.competenceFormData = {
                        ...competenceStore.competenceFormData,
                        name: {
                          ...competenceStore.competenceFormData!.name,
                          en: newValue,
                        },
                      }

                      competenceStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.competenceNameENPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.competenceNameENLabel}
                    inputProps={{
                      autoComplete: 'competence-name-en',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.nameEN && errors.nameEN && <FormHelperText error>{errors.nameEN}</FormHelperText>}
                </Stack>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.nameES && errors.nameES)}
                    type="text"
                    value={competenceStore.competenceFormData!.name!.es}
                    name="nameES"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      competenceStore.competenceFormData = {
                        ...competenceStore.competenceFormData,
                        name: {
                          ...competenceStore.competenceFormData!.name,
                          es: newValue,
                        },
                      }
                      competenceStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.competenceNameESPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.competenceNameESLabel}
                    inputProps={{
                      autoComplete: 'competence-name-es',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.nameES && errors.nameES && <FormHelperText error>{errors.nameES}</FormHelperText>}
                </Stack>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.descriptionPT && errors.descriptionPT)}
                    multiline={true}
                    rows={6}
                    type="text"
                    value={competenceStore.competenceFormData!.description!.pt}
                    name="descriptionPT"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      competenceStore.competenceFormData = {
                        ...competenceStore.competenceFormData,
                        description: {
                          ...competenceStore.competenceFormData!.description,
                          pt: newValue,
                        },
                      }
                      competenceStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.competenceDescriptionPTPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.competenceDescriptionPTLabel}
                    inputProps={{
                      autoComplete: 'competence-description-pt',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.descriptionPT && errors.descriptionPT && (
                    <FormHelperText error>{errors.descriptionPT}</FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.descriptionEN && errors.descriptionEN)}
                    multiline={true}
                    rows={6}
                    type="text"
                    value={competenceStore.competenceFormData!.description!.en}
                    name="descriptionEN"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      competenceStore.competenceFormData = {
                        ...competenceStore.competenceFormData,
                        description: {
                          ...competenceStore.competenceFormData!.description,
                          en: newValue,
                        },
                      }
                      competenceStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.competenceDescriptionENPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.competenceDescriptionENLabel}
                    inputProps={{
                      autoComplete: 'competence-description-en',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.descriptionEN && errors.descriptionEN && (
                    <FormHelperText error>{errors.descriptionEN}</FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.descriptionES && errors.descriptionES)}
                    multiline={true}
                    rows={6}
                    type="text"
                    value={competenceStore.competenceFormData!.description!.es}
                    name="descriptionES"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      competenceStore.competenceFormData = {
                        ...competenceStore.competenceFormData,
                        description: {
                          ...competenceStore.competenceFormData!.description,
                          es: newValue,
                        },
                      }
                      competenceStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.competenceDescriptionESPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.competenceDescriptionESLabel}
                    inputProps={{
                      autoComplete: 'competence-description-es',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.descriptionES && errors.descriptionES && (
                    <FormHelperText error>{errors.descriptionES}</FormHelperText>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </form>
        )
      }}
    </Formik>
  )
}

export default observer(AddCompetenceStep1)
