import { makeAutoObservable } from 'mobx'
import { CompetenceService } from '../services/competence.service'
import { ToastStore } from './toast.store'
import { AuthStore } from './auth.store'
import { Competence, CompetenceForm, CompetenceValue } from '../models/competence.model'
import { Pagination } from '../models/pagination.model'
import { I18nStore } from './i18n.store'

export class CompetenceStore {
  private _competencies: Pagination<Array<Competence>> | null = null
  private _competence: Competence | null = null
  private _competenceFormData: CompetenceForm = new CompetenceForm()
  private _competenceFormSteps: Array<{ title: string; completed?: boolean }> = []
  private _competenceFormValid: boolean = false
  private _competenciesListPage: number = parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string)

  toastStore: ToastStore
  authStore: AuthStore
  i18nStore: I18nStore

  constructor(authStore: AuthStore, i18nStore: I18nStore, toastStore: ToastStore) {
    makeAutoObservable(this)
    this.toastStore = toastStore
    this.authStore = authStore
    this.i18nStore = i18nStore
  }

  async save(competence: CompetenceForm, isDraft: boolean): Promise<Competence | undefined> {
    try {
      const competenceService = new CompetenceService()
      const savedCompetence = await competenceService.save(competence, isDraft)
      this.reloadList()
      return savedCompetence
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async reloadList() {
    this.competenciesListPage = 1
    this.listCompetencies(
      this.authStore.tokenData!.tenant,
      this.competenciesListPage,
      parseInt(process.env.REACT_APP_DATA_LIMIT as string),
      'createdAt',
      'desc',
      ''
    )
  }

  async listCompetencies(
    tenandId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const competenceService = new CompetenceService()
      const res: Pagination<Array<Competence>> = await competenceService.listCompetencies(
        tenandId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )

      res.paginatedData.map((competence) => (competence.checked = false))

      if (res && page === 1) {
        this.competencies = res
      } else {
        this.pushCompetencies(res.paginatedData)
      }
    } catch (e: any) {
      this.competencies = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async deleteCompetence(id: string): Promise<Competence | undefined> {
    try {
      const competenceService = new CompetenceService()
      const deleted = await competenceService.deleteCompetence(id)
      this.reloadList()
      return deleted
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async getById(id: string): Promise<void> {
    try {
      const competenceService = new CompetenceService()
      const res: Competence = await competenceService.getById(id)
      this.competence = res
    } catch (e: any) {
      this.competence = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  set competencies(competencies: Pagination<Array<Competence>> | null) {
    this._competencies = competencies
  }

  updateCompetenciesList = (index: number, newObject: Competence) => {
    if (this._competencies) {
      this._competencies.paginatedData[index] = newObject
      this._competencies = { ...this._competencies }
    }
  }

  get competenciesListPage() {
    return this._competenciesListPage
  }

  set competenciesListPage(page: number) {
    this._competenciesListPage = page
  }

  get competencies() {
    return this._competencies
  }

  get competenceFormData() {
    return this._competenceFormData
  }

  set competenceFormData(competence: CompetenceForm) {
    this._competenceFormData = competence
  }

  set competenceFormSteps(steps: Array<{ title: string; completed?: boolean }>) {
    this._competenceFormSteps = steps
  }

  get competenceFormSteps() {
    return this._competenceFormSteps
  }

  set competence(competence: Competence | null) {
    this._competence = competence
  }

  get competence() {
    return this._competence
  }

  get competenceFormValid() {
    return this._competenceFormValid
  }

  set competenceFormValid(isValid: boolean) {
    this._competenceFormValid = isValid
  }

  pushCompetencies = (competencies: Array<Competence>) =>
    (this._competencies!.paginatedData = [...this._competencies!.paginatedData, ...competencies])

  pushCompetence = (competence: Competence) =>
    (this._competencies!.paginatedData = [...this._competencies!.paginatedData, competence])

  changeCompetenceValue = (field: string, index: number, value: string | boolean, translation?: string) => {
    if (translation) {
      ;(this.competenceFormData!.competenceValues![index] as any)[field][translation] = value
    } else {
      ;(this.competenceFormData!.competenceValues![index] as any)[field] = value
    }
  }

  addCompetenceValue = (competenceValue: CompetenceValue) => {
    this.competenceFormData!.competenceValues?.push(competenceValue)
    this.competenceFormData = { ...this.competenceFormData }
  }

  toggleRow = (index: number) => {
    if (
      this.competenceFormData &&
      this.competenceFormData.competenceValues &&
      this.competenceFormData.competenceValues[index]
    ) {
      if (typeof this.competenceFormData.competenceValues[index].showMore !== 'undefined') {
        this.competenceFormData.competenceValues[index].showMore =
          !this.competenceFormData.competenceValues[index].showMore
      } else {
        this.competenceFormData.competenceValues[index].showMore = false
      }
      this.competenceFormData = { ...this.competenceFormData }
    }
  }

  handleStepCompleted = (step: number, completed: boolean) => (this.competenceFormSteps[step].completed = completed)

  validateStep1 = () => {
    let isValid = false
    if (this.competenceFormData) {
      isValid =
        this.competenceFormData.name!.pt === '' ||
        this.competenceFormData.name!.en === '' ||
        this.competenceFormData.name!.es === '' ||
        this.competenceFormData.description!.pt === '' ||
        this.competenceFormData.description!.en === '' ||
        this.competenceFormData.description!.es === ''
    }
    return !isValid
  }

  validateStep2 = () => {
    if (this.competenceFormData.competenceValues && this.competenceFormData.competenceValues.length > 0) {
      const isValid = this.competenceFormData.competenceValues?.map((competenceValue) => {
        if (competenceValue?.name.pt === '' || competenceValue?.name.en === '' || competenceValue?.name.es === '') {
          return false
        }
        return true
      })
      const allValuesAreTrue = isValid!.every((value) => value === true)
      return allValuesAreTrue
    }
    return false
  }

  validateStep = (step: number) => {
    const step1Valid = this.validateStep1()
    const step2Valid = this.validateStep2()
    if (step === 0) {
      this.handleStepCompleted(0, step1Valid)
    }

    if (step === 1) {
      this.handleStepCompleted(1, step2Valid)
    }

    if (step1Valid && step2Valid) {
      this.competenceFormValid = true
    } else {
      this.competenceFormValid = false
    }
  }
}
