/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  Grid,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import { AssessmentEvaluationForm, AssessmentSteps } from '../../models/assessment.model'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ProgressBarWithLabel from '../../components/progress-bar'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ButtonLoader from '../../components/button-loader'
import SaveIcon from '@mui/icons-material/Save'
import { trackPromise } from 'react-promise-tracker'
import AssessmentEvaluateUserSuccess from './components/assessment-evaluate-user-success'
import AssessmentEvaluateUserNavigation from './components/assessment-evaluate-user-navigation'
import { action } from 'mobx'
import { MobileDatePicker } from '@mui/x-date-pickers'

const AssessmentEvaluateUserPage: React.FC = () => {
  const { i18nStore, assessmentStore, breadcrumbStore, authStore, toastStore, generalStore } = useContext(StoreContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useParams()

  useEffect(() => {
    const getById = async (id: string) => {
      const userId = params.user

      if (userId) {
        await assessmentStore.getAssessmentData(id, userId)
        if (params.id) {
          assessmentStore.assessmentEvaluationForm = new AssessmentEvaluationForm(params.id, userId)
          assessmentStore.setAssessmentEvaluationFormDefaultValues()
        }

        if (searchParams.get('edit') || searchParams.get('view')) {
          await assessmentStore.getAssessmentUserAnswers(id, userId)

          if (isManagerStep()) {
            if (assessmentStore.assessmentEvaluationForm!.answersManager.length === 0) {
              assessmentStore.setAssessmentEvaluationFormDefaultValues()
            } else {
              assessmentStore.assessmentEvaluationForm!.answersManager.forEach((competence, i) => {
                competence.competenceValues.forEach((_, j) => {
                  assessmentStore.checkCompetenceValueValidity(i, j, isManagerStep())
                })
              })
            }
          } else {
            assessmentStore.assessmentEvaluationForm!.answers.forEach((competence, i) => {
              competence.competenceValues.forEach((_, j) => {
                assessmentStore.checkCompetenceValueValidity(i, j, isManagerStep())
              })
            })
          }
        }
      }

      if (assessmentStore.assessment && params.id) {
        let userBackBreadcrumb = [
          {
            name: i18nStore.dictionary.myAssessments,
            link: '/secure/assessment',
          },
          {
            name: assessmentStore.assessment.name[i18nStore.language],
            link: `/secure/assessment/${assessmentStore.assessment._id}`,
          },
        ]
        if (searchParams.get('step')) {
          userBackBreadcrumb = [
            {
              name: i18nStore.dictionary.manageAssessments,
              link: '/secure/manage-assessments',
            },
            {
              name: assessmentStore.assessment.name[i18nStore.language],
              link: `/secure/assessment/${assessmentStore.assessment._id}/summary`,
            },
            {
              name:
                assessmentStore.assessmentUserData!.user.firstName +
                ' ' +
                assessmentStore.assessmentUserData!.user.lastName,
              link: ``,
            },
          ]
        }

        breadcrumbStore.setBreadcrumbItems(userBackBreadcrumb)
      }
    }

    if (params.id && params.user) {
      getById(params.id)
    }
  }, [assessmentStore, params.id, i18nStore, breadcrumbStore, authStore.tokenData, searchParams, params.user])

  const handleSave = async (draft: boolean) => {
    const isEditting: boolean = searchParams.get('edit') ? true : false
    if (assessmentStore.assessmentEvaluationForm) {
      if (draft) {
        await trackPromise(
          assessmentStore.saveEvaluation(assessmentStore.assessmentEvaluationForm, isEditting, isManagerStep()),
          'saving-draft'
        )
        toastStore.pushToast({ message: i18nStore.dictionary.draftSaved, type: 'success' })
        if (!searchParams.get('edit')) setSearchParams('edit=true')
      } else {
        await trackPromise(
          assessmentStore.saveEvaluation(assessmentStore.assessmentEvaluationForm, isEditting, isManagerStep()),
          'finishing-evaluation'
        )
      }
    }
  }

  const isManagerStep = (): boolean => {
    return Boolean(
      searchParams.get('step') &&
        (searchParams.get('step') === 'managers_evaluation' || searchParams.get('step') === 'evaluation_completed')
    )
  }

  const toggleCompetence = (i: number): boolean => {
    if (assessmentStore.assessmentEvaluationForm) {
      if (isManagerStep()) {
        return assessmentStore.assessmentEvaluationForm.currentCompetenceManager === i + 1
      }
      return assessmentStore.assessmentEvaluationForm.currentCompetence === i + 1
    }
    return false
  }

  const handleValueChange = (e: any, field: any, competenceIndex: number, competenceValueIndex: number) => {
    assessmentStore.updateCompetenceValue(e, field, competenceIndex, competenceValueIndex, isManagerStep())
    assessmentStore.checkCompetenceValueValidity(competenceIndex, competenceValueIndex, isManagerStep())
  }

  const checkProgress = (): number => {
    if (
      !searchParams.get('view') &&
      assessmentStore.assessmentEvaluationForm &&
      assessmentStore.assessmentUserData!.step !== 'evaluation_completed'
    ) {
      if (isManagerStep()) {
        return assessmentStore.assessmentEvaluationForm.currentProgressManager
      }
      return assessmentStore.assessmentEvaluationForm.currentProgress
    }
    return 100
  }

  const handleFieldValue = (i: number, j: number, field: string) => {
    if (assessmentStore.assessmentEvaluationForm) {
      if (isManagerStep()) {
        if (
          assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field] === 0 ||
          assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field] === ''
        ) {
          if (field === 'reached') {
            return assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[j][field]
          }
        }

        return assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field]
      }
      return assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[j][field]
    }
    return null
  }

  return (
    assessmentStore.assessment && (
      <Fragment>
        <Box>
          <BreadCrumb />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h2">{assessmentStore.assessment.name[i18nStore.language]}</Typography>

            <Chip
              sx={{ ml: 2 }}
              label={generalStore.getStatusTranslated(searchParams.get('view') ? 'done' : 'in_progress')}
              color={generalStore.getBackgroundByStatus(searchParams.get('view') ? 'done' : 'in_progress')}
              size="small"
            />
          </Box>

          <Typography variant="body2" sx={{ mt: 2 }}>
            {i18nStore.dictionary.limiteDate}:{' '}
            {new Date(assessmentStore.assessment.endDate!).toLocaleDateString(i18nStore.locale)}
          </Typography>
        </Box>

        <ContentArea>
          {assessmentStore.assessmentUserData && assessmentStore.assessmentEvaluationForm && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {assessmentStore.assessmentUserData.step !== 'evaluation_completed' && (
                    <ButtonLoader
                      onClick={() => handleSave(true)}
                      variant="text"
                      loadingText={i18nStore.dictionary.loading}
                      text={
                        <Fragment>
                          <SaveIcon sx={{ mr: 2 }} />
                          <Typography variant="button">{i18nStore.dictionary.saveDraft}</Typography>
                        </Fragment>
                      }
                      area="saving-draft"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    />
                  )}
                  <Chip
                    label={assessmentStore.getStepLabel(assessmentStore.assessmentUserData.step as AssessmentSteps)}
                    color={generalStore.getBackgroundByStatus(assessmentStore.assessmentUserData.progress)}
                    size="small"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <AssessmentEvaluateUserNavigation isManagerStep={isManagerStep} handleSave={handleSave} />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <ProgressBarWithLabel variant="determinate" value={checkProgress()} />
              </Grid>
              {assessmentStore.assessment.competencies &&
                assessmentStore.assessment.competencies.map((competence, i) => {
                  return (
                    <Grid container key={i} sx={{ mx: 4, my: 6, display: toggleCompetence(i) ? 'flex' : 'none' }}>
                      <Grid item xs={12} textAlign="center">
                        <Typography variant="h2">{competence.name[i18nStore.language]}</Typography>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                          {competence.description[i18nStore.language]}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} textAlign="center" sx={{ mt: 3 }}>
                        {competence.competenceValues.map((competenceValue, j) => {
                          return (
                            <Accordion key={j} sx={{ boxShadow: 1, '&:not(:first-of-type)': { mt: 2 } }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon color="primary" />}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {!competenceValue.isValid ? (
                                  <WarningIcon sx={{ mr: 2 }} color="warning" />
                                ) : (
                                  <CheckCircleIcon sx={{ mr: 2 }} color="success" />
                                )}
                                <Typography variant="subtitle1">{competenceValue.name[i18nStore.language]}</Typography>
                              </AccordionSummary>
                              <AccordionDetails sx={{ mt: 2 }}>
                                {competenceValue.allowCertification && (
                                  <Grid container spacing={4} alignItems="flex-end">
                                    <Grid item xs={12} sm={4} textAlign="left">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            disabled={isManagerStep() || Boolean(searchParams.get('view'))}
                                            name={`hasCertification.${i}.${j}`}
                                            sx={{ color: 'primary.main' }}
                                            onChange={(e) => {
                                              action(() => {
                                                assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[
                                                  j
                                                ] = {
                                                  ...assessmentStore.assessmentEvaluationForm!.answers[i]
                                                    .competenceValues[j],
                                                  certification: {
                                                    hasCertification:
                                                      !assessmentStore.assessmentEvaluationForm!.answers[i]
                                                        .competenceValues[j].certification?.hasCertification,
                                                  },
                                                }
                                              })()
                                            }}
                                            value={Boolean(
                                              assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                .certification?.hasCertification
                                            )}
                                            checked={
                                              !!assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                .certification?.hasCertification
                                            }
                                          />
                                        }
                                        label={
                                          Boolean(
                                            assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                              .certification?.hasCertification
                                          )
                                            ? i18nStore.dictionary.hasCertification + ' ' + i18nStore.dictionary.yes
                                            : i18nStore.dictionary.hasCertification + ' ' + i18nStore.dictionary.no
                                        }
                                      />
                                    </Grid>

                                    {assessmentStore.assessmentEvaluationForm &&
                                      assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[j]
                                        .certification &&
                                      Boolean(
                                        assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[j]
                                          .certification!.hasCertification
                                      ) && (
                                        <Grid item xs={12} sm={6} textAlign="left">
                                          <MobileDatePicker
                                            disabled={isManagerStep() || Boolean(searchParams.get('view'))}
                                            disablePast
                                            label={i18nStore.dictionary.validity}
                                            value={
                                              assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                .certification?.expirationDate ?? new Date()
                                            }
                                            onChange={(newValue) => {
                                              action(() => {
                                                if (assessmentStore.assessmentEvaluationForm && newValue) {
                                                  assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[
                                                    j
                                                  ] = {
                                                    ...assessmentStore.assessmentEvaluationForm.answers[i]
                                                      .competenceValues[j],
                                                    certification: {
                                                      ...assessmentStore.assessmentEvaluationForm.answers[i]
                                                        .competenceValues[j].certification,
                                                      expirationDate: newValue,
                                                    },
                                                  }
                                                }
                                              })()
                                            }}
                                            renderInput={(params) => <TextField variant="standard" {...params} />}
                                          />
                                        </Grid>
                                      )}
                                  </Grid>
                                )}

                                <Grid container spacing={4} sx={{ mt: 4 }}>
                                  <Grid item xs={12} sm={4}>
                                    <Slider
                                      disabled={Boolean(searchParams.get('view'))}
                                      name={`reached.${i}.${j}`}
                                      value={handleFieldValue(i, j, 'reached')}
                                      defaultValue={handleFieldValue(i, j, 'reached')}
                                      step={1}
                                      marks
                                      min={1}
                                      max={5}
                                      valueLabelDisplay="on"
                                      onChange={(e) => handleValueChange(e, 'reached', i, j)}
                                      color={
                                        isManagerStep() &&
                                        assessmentStore.assessmentEvaluationForm!.answersManager[i].competenceValues[j][
                                          'reached'
                                        ] === 0
                                          ? 'secondary'
                                          : 'primary'
                                      }
                                    />
                                    <Typography variant="subtitle1">{i18nStore.dictionary.reached}</Typography>
                                  </Grid>
                                  {isManagerStep() && (
                                    <Fragment>
                                      <Grid item xs={12} sm={4}>
                                        <Slider
                                          disabled={Boolean(searchParams.get('view'))}
                                          name={`expected.${i}.${j}`}
                                          value={handleFieldValue(i, j, 'expected')}
                                          defaultValue={handleFieldValue(i, j, 'expected')}
                                          step={1}
                                          marks
                                          min={1}
                                          max={5}
                                          valueLabelDisplay="on"
                                          onChange={(e) => handleValueChange(e, 'expected', i, j)}
                                        />
                                        <Typography variant="subtitle1">{i18nStore.dictionary.expected}</Typography>
                                      </Grid>

                                      <Grid item xs={12} sm={4}>
                                        <Slider
                                          disabled={Boolean(searchParams.get('view'))}
                                          name={`priority.${i}.${j}`}
                                          value={handleFieldValue(i, j, 'priority')}
                                          defaultValue={handleFieldValue(i, j, 'priority')}
                                          step={1}
                                          marks
                                          min={1}
                                          max={5}
                                          valueLabelDisplay="on"
                                          onChange={(e) => handleValueChange(e, 'priority', i, j)}
                                        />
                                        <Typography variant="subtitle1">{i18nStore.dictionary.priority}</Typography>
                                      </Grid>
                                    </Fragment>
                                  )}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          )
                        })}
                      </Grid>
                    </Grid>
                  )
                })}

              <AssessmentEvaluateUserSuccess step={searchParams.get('step')} view={searchParams.get('view')} />
            </Grid>
          )}
        </ContentArea>
      </Fragment>
    )
  )
}

export default observer(AssessmentEvaluateUserPage)
