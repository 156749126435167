import { Box, Typography, Grid, styled, Card, CardActions, CardContent, CardHeader, Chip, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect } from 'react'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import { Billing } from '../../models/billing.model'
import ButtonLoader from '../../components/button-loader'
import NotFoundData from '../../components/not-found'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 2px 5px -1px ${theme.palette.grey[300]}, 0px 1px 5px 0px ${theme.palette.grey[200]}, 0px 1px 15px 0px ${theme.palette.grey[100]}`,
}))

const BillingPage: React.FC = () => {
  const { billingStore, authStore, breadcrumbStore, i18nStore, generalStore } = useContext(StoreContext)

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.billingInfo,
        link: '/secure/billing',
      },
    ])
    const listBillings = async () => {
      if (authStore.tokenData) {
        await billingStore.listBillings(
          authStore.tokenData.tenant,
          billingStore.billingsListPage,
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          ''
        )
      }
    }

    listBillings()
  }, [authStore.tokenData, billingStore, breadcrumbStore, i18nStore.dictionary.billingInfo])

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.billingInfo}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.manageBillingInfo}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container>
          <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={5} display="flex"></Grid>

            {billingStore.billings && (
              <Grid container spacing={2}>
                {billingStore.billings.paginatedData.map((billing: Billing, index: number) => {
                  return (
                    <Grid item xs={12} sm={4} key={index}>
                      <StyledCard>
                        <CardHeader
                          title={
                            <Typography variant="h5">
                              {i18nStore.dictionary.billingDate +
                                ': ' +
                                new Date(billing.billingDate).toLocaleDateString(i18nStore.locale)}
                            </Typography>
                          }
                          subheader={
                            <Typography variant="caption" color="text.primary">
                              {i18nStore.dictionary.totalUsers + ': ' + billing.currentBillingUsersAmount}
                            </Typography>
                          }
                        />
                        <Divider />
                        <CardContent>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle2">{i18nStore.dictionary.value}: </Typography>
                            <Typography variant="body1" sx={{ ml: 1 }}>
                              $ {billing.currentBilling}
                            </Typography>
                          </Box>
                        </CardContent>
                        <CardActions disableSpacing>
                          <Chip
                            label={generalStore.getStatusTranslated(billing.status)}
                            color={generalStore.getBackgroundByStatus(billing.status)}
                            size="small"
                            sx={{ marginLeft: 'auto' }}
                          />
                        </CardActions>
                      </StyledCard>
                    </Grid>
                  )
                })}

                {billingStore.billings.totalPages === 0 && (
                  <Grid item xs={12}>
                    <NotFoundData />
                  </Grid>
                )}

                {billingStore.billings.totalPages > 0 && (
                  <Grid container sx={{ mt: 6, display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={{ marginLeft: 'auto' }}>
                      <ButtonLoader
                        variant="contained"
                        onClick={() => (billingStore.billingsListPage = billingStore.billingsListPage + 1)}
                        disabled={billingStore.billings.totalPages === billingStore.billingsListPage}
                        loadingText={i18nStore.dictionary.loading}
                        text={i18nStore.dictionary.loadMore}
                        area="load-more"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      sx={{ display: 'flex', mt: { xs: 2, sm: 0 }, justifyContent: { xs: 'center', sm: 'end' } }}
                    >
                      <Typography component="span" variant="subtitle2">
                        {i18nStore.dictionary.showing}
                      </Typography>
                      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
                        {billingStore.billings.paginatedData.length}
                      </Typography>
                      <Typography component="span" variant="subtitle2" sx={{ textTransform: 'lowercase' }}>
                        {i18nStore.dictionary.of}
                      </Typography>
                      <Typography component="span" variant="subtitle2" sx={{ ml: 1 }}>
                        {billingStore.billings.totalItems}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(BillingPage)
