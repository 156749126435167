import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import { SearchOutlined } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import { UserData } from '../../../models/user.model'
import NotFoundData from '../../../components/not-found'

interface UsersListModalProps {
  emitSelected: Function
}

const UsersListModal: React.FC<UsersListModalProps> = (props: UsersListModalProps) => {
  const { modalStore, i18nStore, authStore, userStore } = useContext(StoreContext)
  const [search, setSearch] = useState('')
  const [tempSearch, setTempSearch] = useState('')
  const [selectedUsers, setUsers] = useState([] as Array<UserData>)
  let [checkedAll, setCheckedAll] = useState(false)

  const searchByUserName = (event: any) => setSearch(event.target.value as string)

  const handleToggle = (user: UserData, index: number) => {
    let newChecked = [...selectedUsers]

    const exists = selectedUsers.some((obj: any) => obj._id === user._id)
    if (!exists) {
      newChecked.push(user)
    } else {
      newChecked = newChecked.filter((obj: any) => obj._id !== user._id)
    }

    user = { ...user, checked: !user.checked }
    userStore.updateUsersList(index, user)

    setUsers(newChecked)

    if (newChecked.length === 0) {
      setCheckedAll(false)
    }
  }

  const handleToggleAll = () => {
    if (userStore.users) {
      setCheckedAll((checkedAll = !checkedAll))
      let newChecked = [...selectedUsers]
      userStore.users.paginatedData.forEach((user: UserData, index: number) => {
        if (checkedAll) {
          newChecked.push(user)
        } else {
          newChecked = []
        }

        user = { ...user, checked: checkedAll }
        userStore.updateUsersList(index, user)
        setUsers(newChecked)
      })
    }
  }

  useEffect(() => {
    const listUsers = async () => {
      if (authStore.tokenData) {
        var filter = JSON.stringify({
          name: search,
        })

        await userStore.listUsersByTenant(
          authStore.tokenData.tenant,
          parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          filter
        )
      }
    }
    listUsers()
  }, [authStore, userStore, i18nStore.dictionary, search])

  const confirm = async () => {
    props.emitSelected(selectedUsers)
    modalStore.handleClose()
  }

  return (
    <Fragment>
      <Typography variant="h4" color={'text.primary'} align="center" sx={{ my: 3 }}>
        {i18nStore.dictionary.selectUsers}
      </Typography>

      {userStore.users && (
        <Grid container>
          <Grid item xs={12} display="flex">
            <TextField
              label={i18nStore.dictionary.searchByUserName}
              value={tempSearch}
              onChange={(e) => setTempSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') searchByUserName(e)
              }}
              fullWidth
              placeholder={i18nStore.dictionary.searchByUserName}
              InputProps={{
                startAdornment: (
                  <IconButton color="primary" onClick={() => setSearch(tempSearch)}>
                    <SearchOutlined />
                  </IconButton>
                ),
                endAdornment: tempSearch && (
                  <IconButton
                    onClick={() => {
                      setSearch('')
                      setTempSearch('')
                    }}
                  >
                    <CancelIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {userStore.users.paginatedData.length > 0 ? (
              <List sx={{ width: '100%', maxWidth: 360 }}>
                <ListItem disablePadding>
                  <ListItemButton role={undefined} onClick={() => handleToggleAll()} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        sx={{ color: 'primary.main' }}
                        size="small"
                        checked={checkedAll}
                        value={checkedAll}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={i18nStore.dictionary.selectAll} />
                  </ListItemButton>
                </ListItem>

                {userStore.users.paginatedData.map((user, index) => {
                  const labelId = `checkbox-list-label-${index}`

                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton role={undefined} onClick={() => handleToggle(user, index)} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            sx={{ color: 'primary.main' }}
                            size="small"
                            checked={user.checked}
                            value={user._id}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={user.firstName} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            ) : (
              <NotFoundData />
            )}
          </Grid>
        </Grid>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
        <Button
          disableElevation
          size="large"
          type="button"
          variant="text"
          color="primary"
          onClick={() => modalStore.handleClose()}
        >
          {i18nStore.dictionary.back}
        </Button>

        {userStore.users && userStore.users.paginatedData.length > 0 && (
          <ButtonLoader loadingText="Processando..." text={i18nStore.dictionary.confirm} onClick={() => confirm()} />
        )}
      </Box>
    </Fragment>
  )
}

export default observer(UsersListModal)
