import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import ContentArea from '../../components/content-area'
import { UserData } from '../../models/user.model'
import ButtonLoader from '../../components/button-loader'
import InviteUserForm from './forms/invite-user-form'
import { ModalOpts } from '../../models/modal.model'
import UserMenu from './menus/user-menu'
import NotFoundData from '../../components/not-found'
import { SearchOutlined } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 2px 5px -1px ${theme.palette.grey[300]}, 0px 1px 5px 0px ${theme.palette.grey[200]}, 0px 1px 15px 0px ${theme.palette.grey[100]}`,
}))

const ManageUsersPage: React.FC = () => {
  let [page, setPage] = useState<number>(1)
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')
  const [tempSearch, setTempSearch] = useState('')

  const { userStore, authStore, breadcrumbStore, i18nStore, modalStore, generalStore } = useContext(StoreContext)

  const searchByUserName = (event: any) => setSearch(event.target.value as string)

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.manageUsers,
        link: '/secure/manage-users',
      },
    ])
    const loadUsersByTenant = async () => {
      if (authStore.tokenData) {
        var filter = JSON.stringify({
          name: search,
          status: status === 'all' ? '' : status,
        })

        await userStore.listUsersByTenant(
          authStore.tokenData.tenant,
          parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          filter
        )
      }
    }

    loadUsersByTenant()
  }, [authStore, userStore, breadcrumbStore, i18nStore.dictionary, search])

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.manageUsers}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.manageUsersSubtitle}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container>
          <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={5} display="flex">
              <TextField
                label={i18nStore.dictionary.searchByUserName}
                value={tempSearch}
                onChange={(e) => setTempSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') searchByUserName(e)
                }}
                fullWidth
                placeholder={i18nStore.dictionary.searchByUserName}
                InputProps={{
                  startAdornment: (
                    <IconButton color="primary" onClick={() => setSearch(tempSearch)}>
                      <SearchOutlined />
                    </IconButton>
                  ),
                  endAdornment: tempSearch && (
                    <IconButton
                      onClick={() => {
                        setSearch('')
                        setTempSearch('')
                      }}
                    >
                      <CancelIcon sx={{ color: 'primary.main' }} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              display="flex"
              sx={{ justifyContent: { xs: 'center', sm: 'end' }, mt: { xs: 2, sm: 0 } }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  const modalOpts: ModalOpts = {
                    size: 'md',
                  }
                  modalStore.openModal(<InviteUserForm />, modalOpts)
                }}
              >
                {i18nStore.dictionary.inviteEmployee}
              </Button>
            </Grid>
          </Grid>

          <Grid container sx={{ my: 3 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {userStore.users && (
            <Grid container spacing={2}>
              {userStore.users.paginatedData.map((user: UserData, index: number) => {
                return (
                  <Grid item xs={12} sm={4} key={index}>
                    <StyledCard>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: 'secondary.main' }}>
                            {userStore.getUserInitials(user.firstName, user.lastName)}
                          </Avatar>
                        }
                        action={<UserMenu user={user} />}
                        title={<Typography variant="h5">{user.firstName + ' ' + user.lastName}</Typography>}
                        subheader={
                          <Typography variant="caption" color="text.primary">
                            {new Date(user.createdAt).toLocaleDateString('pt-BR', {
                              hour: 'numeric',
                              minute: 'numeric',
                            })}
                          </Typography>
                        }
                      />
                      <Divider />
                      <CardContent>
                        <Typography variant="h6">{user.firstName + ' ' + user.lastName}</Typography>
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                          {user.role}
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing>
                        <Chip
                          label={generalStore.getStatusTranslated(user.status)}
                          color={generalStore.getBackgroundByStatus(user.status)}
                          size="small"
                          sx={{ marginLeft: 'auto' }}
                        />
                      </CardActions>
                    </StyledCard>
                  </Grid>
                )
              })}

              {userStore.users.totalPages === 0 && (
                <Grid item xs={12}>
                  <NotFoundData
                    children={
                      <Button
                        sx={{ mt: 2 }}
                        variant="text"
                        onClick={() => {
                          const modalOpts: ModalOpts = {
                            size: 'md',
                          }
                          modalStore.openModal(<InviteUserForm />, modalOpts)
                        }}
                      >
                        {i18nStore.dictionary.startAddingUser}
                      </Button>
                    }
                  />
                </Grid>
              )}

              {userStore.users.totalPages > 0 && (
                <Grid container sx={{ mt: 6, display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={{ marginLeft: 'auto' }}>
                    <ButtonLoader
                      variant="contained"
                      onClick={() => setPage((page = page + 1))}
                      disabled={userStore.users.totalPages === page}
                      loadingText={i18nStore.dictionary.loading}
                      text={i18nStore.dictionary.loadMore}
                      area="auth"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ display: 'flex', mt: { xs: 2, sm: 0 }, justifyContent: { xs: 'center', sm: 'end' } }}
                  >
                    <Typography component="span" variant="subtitle2">
                      {i18nStore.dictionary.showing}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
                      {userStore.users.totalItems}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ textTransform: 'lowercase' }}>
                      {i18nStore.dictionary.of}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ ml: 1 }}>
                      {userStore.users.paginatedData.length}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(ManageUsersPage)
