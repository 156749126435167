const DictionaryES = {
  manageUsers: 'Administrar usuarios',
  manageUsersSubtitle: 'Administre su usuarios',
  showing: 'Demostración',
  of: 'De',
  loadMore: 'Carga más',
  loading: 'Cargando',
  searchByUserName: 'Buscar por nombre de empleado',
  inviteEmployee: 'Invitar colaborador',
  requiredField: 'Campo requerido',
  invalidEmail: 'Correo electrónico inválido',
  fillNewEmployeeDataTitle: 'Rellene los datos del nuevo empleado',
  nameLabel: 'Nombre',
  namePlaceholder: 'Ingrese el nombre',
  lastNameLabel: 'Apellido',
  lastNamePlaceholder: 'Ingrese el apellido',
  emailPlaceholder: 'Ingrese el correo electrónico corporativo del empleado',
  emailLabel: 'Correo electrónico',
  sendInvite: 'Enviar invitación',
  role: 'Role',
  inviteSended: '¡Invitación enviada con éxito!',
  invalidDomain: 'Dominio inválido',
  user: 'Usuario',
  evaluator: 'Evaluador',
  delete: 'Borrar',
  areYouSureDeleteUser: '¿Tem certeza de que desea eliminar esse usuário?',
  cannotBeUndoneAction: 'Esta acción no se puede deshacer.',
  noBack: 'No, vuelve atrás',
  userDeleted: 'Usuario eliminado con éxito',
  statusNew: 'Nuevo',
  statusActive: 'Activo',
  statusDraft: 'Bosquejo',
  statusPending: 'Pendiente',
  statusInvoiced: 'Facturado',
  resendActivationLink: 'Enviar enlace de activación',
  activationLinkSended: 'Enlace de activación enviado con éxito',
  welcome: 'Bienvenido',
  activateAccountMessage: 'Para activar su cuenta debe configurar su contraseña',
  passwordMustContainUppercase: 'Debe contener al menos una letra mayúscula',
  passwordMustContainNumber: 'Debe contener al menos un número',
  passwordMustContainSpecialChar: 'Debe contener al menos un carácter especial',
  passwordMustContainSixChars: 'Debe contener al menos 6 caracteres',
  passwordPlaceholder: 'Ingrese su contraseña',
  passwordLabel: 'Contraseña',
  confirmPasswordPlaceholder: 'Vuelva a escribir su contraseña',
  confirmPasswordLabel: 'Confirmación de contraseña',
  passwordDontMatch: 'Contraseña no coincide',
  invalidToken: 'Token no válido',
  activate: 'Activar',
  userActivated: 'Usuario activado con éxito',
  redirecting: 'Redireccionando',
  notFoundData: 'No se encontró ningún registro',
  leaving: 'Partida',
  manageCompetencies: 'Gestionar competencias',
  manageCompetenciesSubtitle: 'Gestionar sus competencias',
  searchByCompetenceName: 'Buscar por nombre de competencia',
  addCompetence: 'Agregar competencia',
  startAddingUser: 'Comece agregando un nuevo usuario',
  startAddingCompetence: 'Empezar a agregar una nueva competencia',
  save: 'Ahorrar',
  fillNewCompetenceData: 'Rellene los datos de la nueva competencia',
  competenceNamePTLabel: 'Nombre de la competencia (PT)',
  competenceNamePTPlaceholder: 'Ingrese el nombre que se mostrará en portugués',
  competenceNameENLabel: 'Nombre de la competencia (EN)',
  competenceNameENPlaceholder: 'Ingrese el nombre que se mostrará en inglés',
  competenceNameESLabel: 'Nombre de la competencia (ES)',
  competenceNameESPlaceholder: 'Ingrese el nombre a mostrar en español',
  competenceDescriptionPTLabel: 'Breve descripción de la competencia (PT)',
  competenceDescriptionPTPlaceholder: 'Escriba un resumen de competencias para ser presentado en portugués',
  competenceDescriptionENLabel: 'Breve descripción de la competencia (EN)',
  competenceDescriptionENPlaceholder: 'Escriba un resumen de competencias que se presentará en inglés',
  competenceDescriptionESLabel: 'Descripción breve de la competencia (ES)',
  competenceDescriptionESPlaceholder: 'Escriba un resumen de competencias para presentar en español',
  next: 'Próximo',
  back: 'Atras',
  saveDraft: 'Guardar borrador',
  addCompetenceStep1Title: 'Informaciones básicas',
  addCompetenceStep2Title: 'Valores',
  addCompetenceStep3Title: 'Resumen',
  edit: 'Editar',
  publish: 'Publicar',
  draftSaved: 'borrador guardado',
  pleaseFillCurrentCompetenceValues: 'Ingrese los datos de competencia actuales antes de agregar uno nuevo',
  competence: 'Competencia',
  values: 'Valores',
  competencePublished: 'Competencia publicada con éxito',
  manageAssessments: 'Gestionar evaluaciones',
  manageAssessmentsSubtitle: 'Cree evaluaciones para su equipo',
  createAssessment: 'Crear nueva evaluación',
  searchByAssessmentName: 'Buscar por nombre de evaluación',
  startAddingAssessment: 'Comience creando una nueva evaluación',
  addAssessmentStep1Title: 'Información básica',
  addAssessmentStep2Title: 'Habilidades',
  addAssessmentStep3Title: 'Colaboradores',
  addAssessmentStep4Title: 'Resumen',
  fillNewAssessmentData: 'Rellene los nuevos datos de evaluación',
  assessmentNamePTLabel: 'Nombre de la evaluación (PT)',
  assessmentNamePTPlaceholder: 'Nombre que será presentado en portugués',
  assessmentNameENLabel: 'Nombre de la evaluación (EN)',
  assessmentNameENPlaceholder: 'Nombre que se mostrará en inglés',
  assessmentNameESLabel: 'Nombre de la evaluación (ES)',
  assessmentNameESPlaceholder: 'Nombre que se presentará en española',
  endDate: 'Fecha de finalización',
  informCompetences: 'Agregue las habilidades que compondrán la evaluación',
  confirm: 'Confirmar',
  selectCompetences: 'Seleccionar competencias',
  selectAll: 'Seleccionar todo',
  informUsers: 'Agregar usuarios que podrán responder a la encuesta',
  addUser: 'Añadir Usuario',
  totalUsers: 'Usuarios totales',
  totalCompetencies: 'Competencias totales',
  selectUsers: 'Seleccionar usuarios',
  assessment: 'Evaluación',
  competencies: 'Competencias',
  users: 'Usuarios',
  assessmentPublished: 'Evaluación publicada con éxito',
  myAssessments: 'Mis evaluaciones',
  myAssessmentsSubtitle: 'Seleccione una de las evaluaciones disponibles',
  start: 'Iniciar',
  limiteDate: 'Fecha límite para enviar',
  statusNotStarted: 'No iniciado',
  statusInProgress: 'En progreso',
  statusDone: 'Terminado',
  continue: 'Continuar',
  noAssessmentFound: 'No se encontró ninguna evaluación',
  selfEvaluation: 'Autoevaluación',
  reached: 'Alcanzado',
  expected: 'Esperado',
  priority: 'Prioridad',
  finish: 'Finalizar',
  backAssessments: 'Volver a las evaluaciones',
  view: 'Ver',
  managerEvaluation: 'Evaluación del gerente',
  summary: 'Resumen',
  noNotifications: 'No se encontraron notificaciones',
  markAllAsRead: 'Marcar todo como leído',
  followLink: 'Seguir enlace',
  markAsRead: 'Marcar como leído',
  markAsUnread: 'Marcar como no leído',
  welcomeMessage: 'Hola, bienvenido',
  evaluate: 'Evaluar',
  areYouSuredeleteAssessment: '¿Está seguro de que desea eliminar esta evaluación?',
  allAnswersWillBeDeleted: 'Todas las respuestas y el progreso del usuario también serán eliminados',
  selfEvaluationDone: 'Autoevaluación completada con éxito',
  selfEvaluationDoneMessage: 'Se ha enviado una notificación a su gerente para continuar con el siguiente paso',
  managerEvaluationDone: 'Evaluación completada con éxito',
  managerEvaluationDoneMessage: 'Se ha enviado una notificación al empleado',
  statusWaiting: 'Esperando',
  evaluationDone: 'Evaluación finalizada',
  asssessmentDeleted: 'Evaluación eliminada con éxito',
  usersWaitingEvaluation: 'Usuarios en espera de evaluación',
  viewAll: 'Ver todo',
  employee: 'Empleado',
  lastUpdate: 'Última actualización',
  status: 'Estado',
  showingLastSix: 'Mostrando los últimos 6 registros',
  waitingEvaluations: 'Esperando evaluaciones',
  allowCertification: 'Permitir certificación',
  yes: 'Si',
  no: 'No',
  competenceDeleted: 'Competencia eliminada con éxito',
  areYouSureDeleteCompetence: '¿Está seguro de que desea eliminar esta competencia?',
  hasCertification: 'Tiene certificación',
  validity: 'Validez',
  pendingAssessments: 'Evaluaciones pendientes',
  billingInfo: 'Información de facturación',
  manageBillingInfo: 'Administrar información de facturación',
  billingDate: 'Fecha de facturación',
  value: 'Valor',
  profile: 'Perfil',
  keepYourDataUpdated: 'Mantenga sus datos actualizados',
  dataUpdated: 'Datos actualizados',
  update: 'Actualizar',
  profileImageUpdated: 'Imagen de perfil actualizada',
  changeImage: 'Cambiar imagen',
  failedToSendMail: 'Error al enviar el correo electrónico',
  instructionsResetPasswordSendByEmail: 'Instrucciones de recuperación de contraseña enviadas por correo electrónico',
  ok: 'OK',
  resetPasswordTitle:
    'Ingrese su correo electrónico y le enviaremos un enlace con los pasos para restablecer su contraseña',
  send: 'Enviar',
  informEmail: 'Ingrese el correo electrónico',
  setNewPasswordMessage: 'Siga las instrucciones a continuación para restablecer su contraseña',
  reset: 'Reiniciar',
  passwordReseted: 'Contraseña actualizada con éxito',
  welcomeTitle: 'Bienvenido a la plataforma de mapeo de competencias',
  hello: 'Hola',
  loginSubtitle:
    'Identifique las habilidades técnicas y de comportamiento dentro de su empresa. Reconocer a los profesionales alineados con las expectativas y retener el talento',
  forgotMyPassword: 'Olvidé mi contraseña',
  rememberAccess: 'Lembrar acesso',
  signIn: 'Entrar',
  authenticating: 'Autenticando',
  bannerText01: 'Muestra tu potencial',
  bannerText02: 'crecimiento',
  bannerText03: 'Trabaja tus debilidades y muestra las tuyas',
  bannerText04: 'habilidades',
  bannerText05: 'Su empresa tiene mucha gente',
  bannerText06: 'talentosas',
  bannerText07: 'y te ayudaremos',
  bannerText08: 'encuéntrelos',
}

export default DictionaryES
