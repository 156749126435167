import { RouterProvider } from 'react-router-dom'
import Router from './routes'
import ThemeCustomization from './theme'
import CustomModal from './components/modal'
import { StoreContext } from './stores/store.context'
import { useContext } from 'react'
import Snackbar from '@mui/material/Snackbar'
import { observer } from 'mobx-react-lite'
import Alert from '@mui/material/Alert'
import { ToastOptions } from './models/toast.model'

const App = () => {
  const { toastStore } = useContext(StoreContext)

  return (
    <ThemeCustomization>
      <RouterProvider router={Router} />

      <CustomModal />

      {toastStore.getToast().map((toast: ToastOptions, index: React.Key) => (
        <Snackbar
          anchorOrigin={{
            vertical: toast.position ? toast.position.vertical : 'top',
            horizontal: toast.position ? toast.position.horizontal : 'right',
          }}
          open={true}
          key={index}
        >
          <Alert severity={toast.type}>{toast.message}</Alert>
        </Snackbar>
      ))}
    </ThemeCustomization>
  )
}
export default observer(App)
