import React, { Fragment, useContext, useState } from 'react'
import { Avatar, Box, Container, Divider, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../stores/store.context'
import { MainMenuItem } from '../models/menu.model'
import ThemeSwitcher from './theme-switcher'
import LogoutOutlined from '@mui/icons-material/LogoutOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import AppBar from '@mui/material/AppBar'
import LanguageSwitcher from './language-switcher'
import MenuIcon from '@mui/icons-material/Menu'
import Notifications from './notification'

const Navbar: React.FC = () => {
  const { authStore, i18nStore, userStore } = useContext(StoreContext)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  let [mobileMenu, toggleMobileMenu] = useState<boolean>(false)
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget)
  const handleCloseUserMenu = () => setAnchorElUser(null)

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="md">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <ThemeSwitcher />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'right',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {authStore.menu &&
                authStore.menu.items.map((menu: MainMenuItem, index: number) => {
                  return (
                    <Typography
                      variant="body2"
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mr: 2,
                      }}
                    >
                      <Link underline="hover" component={RouterLink} to={menu.routeLink} color={'text.primary'}>
                        {menu.name[i18nStore.language]}
                      </Link>
                    </Typography>
                  )
                })}
              <LanguageSwitcher />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" onClick={() => toggleMobileMenu((mobileMenu = !mobileMenu))} color="primary">
                <MenuIcon />
              </IconButton>

              {mobileMenu && (
                <Fragment>
                  <Box
                    onClick={() => toggleMobileMenu((mobileMenu = !mobileMenu))}
                    sx={{
                      position: 'fixed',
                      height: '100vh',
                      left: 0,
                      top: 0,
                      backgroundColor: 'grey.500',
                      opacity: 0.5,
                      width: '100vw',
                      zIndex: 98,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      position: 'fixed',
                      height: '100vh',
                      left: 0,
                      top: 0,
                      backgroundColor: 'background.default',
                      width: '80vw',
                      zIndex: 99,
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 6, pt: 6 }}>
                      <LanguageSwitcher />

                      {authStore.menu &&
                        authStore.menu.items.map((menu: MainMenuItem, index: number) => {
                          return (
                            <Typography
                              variant="body2"
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                ':not(:first-of-type)': {
                                  mt: 2,
                                },
                                ':first-of-type': {
                                  mt: 4,
                                },
                              }}
                            >
                              <Link
                                onClick={() => toggleMobileMenu((mobileMenu = !mobileMenu))}
                                underline="hover"
                                component={RouterLink}
                                to={menu.routeLink}
                                color={'text.primary'}
                              >
                                {menu.name[i18nStore.language]}
                              </Link>
                            </Typography>
                          )
                        })}
                    </Box>
                  </Box>
                </Fragment>
              )}
            </Box>

            <IconButton
              onClick={handleOpenUserMenu}
              sx={{
                p: 0,
                ml: 2,
              }}
            >
              {authStore.tokenData && !authStore.tokenData.image ? (
                <Avatar
                  sx={{
                    backgroundColor: 'secondary.main',
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {userStore.getUserInitials(authStore.tokenData.firstName, authStore.tokenData.lastName)}
                </Avatar>
              ) : (
                authStore.tokenData && (
                  <Box
                    sx={{
                      objectFit: 'cover',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: 'grey.300',
                    }}
                    component="img"
                    src={process.env.REACT_APP_API + '/uploads/user/' + authStore.tokenData.image}
                  />
                )
              )}
              {!anchorElUser ? (
                <KeyboardArrowDownOutlinedIcon
                  sx={{
                    color: 'primary.main',
                  }}
                />
              ) : (
                <KeyboardArrowUpOutlinedIcon
                  sx={{
                    color: 'primary.main',
                  }}
                />
              )}
            </IconButton>

            <Notifications />

            <Menu
              sx={{
                mt: '45px',
                '& .MuiPaper-root': {
                  backgroundColor: 'background.default',
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => {
                  navigate('/secure/user/profile')
                  handleCloseUserMenu()
                }}
              >
                <Typography
                  variant="body2"
                  textAlign="center"
                  sx={{
                    display: 'flex',
                  }}
                >
                  {authStore.tokenData?.firstName + ' ' + authStore.tokenData?.lastName}
                </Typography>
              </MenuItem>
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <MenuItem onClick={authStore.logout}>
                <LogoutOutlined
                  sx={{
                    mr: 2,
                  }}
                />
                <Typography
                  variant="body2"
                  textAlign="center"
                  sx={{
                    display: 'flex',
                  }}
                >
                  Sair
                </Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}

export default observer(Navbar)
