import { Theme } from '@mui/material/styles'

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
        input: {
          '&:disabled': {
            background: theme.palette.grey[200],
          },
          '[data-autocompleted]': {
            backgroundColor: 'transparent !important',
          },
          '&:-webkit-autofill': {
            transition: 'background-color 600000s 0s, color 600000s 0s',
          },
          '&:-webkit-autofill:focus': {
            transition: 'background-color 600000s 0s, color 600000s 0s',
          },
          '&:-internal-autofill-selected': {
            color: `${theme.palette.text.primary} !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: theme.palette.grey[500],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: `${theme.palette.text.primary}`,
        },
      },
    },
  }
}
