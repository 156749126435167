import { Assessment, AssessmentEvaluationForm, AssessmentForm } from '../models/assessment.model'
import axios from 'axios'
import { Pagination } from '../models/pagination.model'

export class AssessmentService {
  async save(assessment: AssessmentForm, isDraft: boolean) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/assessment/save`, { assessment, isDraft })
      const parsedResponse: Assessment = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async saveEvaluation(assessmentEvaluation: AssessmentEvaluationForm, isEditting: boolean, isManagerStep: boolean) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/assessment/save-evaluation`, {
        assessmentEvaluation,
        isEditting,
        isManagerStep,
      })
      const parsedResponse: Assessment = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async listAssessmentsByTenant(
    tenandId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Assessment>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/assessment/tenant/${tenandId}/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      })
      const parsedResponse: Pagination<Array<Assessment>> = await res.data
      parsedResponse.paginatedData as Assessment[]
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async listUserAssessments(
    userId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Assessment>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/assessment/user/${userId}/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      })
      const parsedResponse: Pagination<Array<Assessment>> = await res.data
      parsedResponse.paginatedData as Assessment[]
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async getById(id: string) {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/assessment/${id}`)
      const parsedResponse: Assessment = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async getAssessmentUserAnswers(assessment: string, user: string) {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/assessment/${assessment}/user/${user}/answers`)
      const parsedResponse: AssessmentEvaluationForm = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async deleteAssessment(id: string): Promise<Assessment> {
    try {
      const res = await axios.delete(process.env.REACT_APP_API + `/assessment/${id}`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }
}
