import { makeAutoObservable } from 'mobx'
import { TenantData } from '../models/tenant.model'
import { TenantService } from '../services/tenant.service'
import { ToastStore } from './toast.store'

export class TenantStore {
  private _tenant: TenantData | null = null
  toastStore: ToastStore

  constructor(toastStore: ToastStore) {
    makeAutoObservable(this)
    this.toastStore = toastStore
  }

  async getTenantById(id: string): Promise<any> {
    try {
      const tenantService = new TenantService()
      const tenant = await tenantService.getTenantById(id)
      this.tenant = tenant
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })
      throw e.response.data
    }
  }

  set tenant(tenantData: TenantData | null) {
    this._tenant = tenantData
  }

  get tenant() {
    return this._tenant
  }
}
