import { makeAutoObservable } from 'mobx'
import { Assessment, AssessmentEvaluationForm, AssessmentForm, AssessmentSteps } from '../models/assessment.model'
import { Competence, CompetenceValue } from '../models/competence.model'
import { Pagination } from '../models/pagination.model'
import { UserData } from '../models/user.model'
import { AssessmentService } from '../services/assessment.service'
import { AuthStore } from './auth.store'
import { I18nStore } from './i18n.store'
import { ToastStore } from './toast.store'

interface AssessmentUserData {
  user: UserData
  progress: string
  step: string
}

export class AssessmentStore {
  private _assessments: Pagination<Array<Assessment>> | null = null
  private _userAssessments: Pagination<Array<Assessment>> | null = null
  private _assessment: Assessment | null = null
  private _assessmentFormSteps: Array<{ title: string; completed?: boolean }> = []
  private _assessmentFormData: AssessmentForm = new AssessmentForm()
  private _assessmentFormValid: boolean = false
  private _assessmentUserData: AssessmentUserData | null = null
  private _assessmentEvaluationForm: AssessmentEvaluationForm | null = null
  private _assessmentsListPage: number = parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string)

  toastStore: ToastStore
  authStore: AuthStore
  i18nStore: I18nStore

  constructor(authStore: AuthStore, i18nStore: I18nStore, toastStore: ToastStore) {
    makeAutoObservable(this)
    this.authStore = authStore
    this.toastStore = toastStore
    this.i18nStore = i18nStore
  }

  async save(assessment: AssessmentForm, isDraft: boolean): Promise<Assessment | undefined> {
    try {
      const assesmentService = new AssessmentService()
      const savedAssessment = await assesmentService.save(assessment, isDraft)

      this.assessmentsListPage = 1
      this.listAssessmentsByTenant(
        this.authStore.tokenData!.tenant,
        this.assessmentsListPage,
        parseInt(process.env.REACT_APP_DATA_LIMIT as string),
        'createdAt',
        'desc',
        ''
      )
      return savedAssessment
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async saveEvaluation(
    formData: AssessmentEvaluationForm,
    isEditting: boolean,
    isManagerStep: boolean
  ): Promise<Assessment | undefined> {
    try {
      const assesmentService = new AssessmentService()
      const saved = await assesmentService.saveEvaluation(formData, isEditting, isManagerStep)
      return saved
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async listAssessmentsByTenant(
    tenandId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const assessmentService = new AssessmentService()
      const res: Pagination<Array<Assessment>> = await assessmentService.listAssessmentsByTenant(
        tenandId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )

      if (res && page === 1) {
        this.assessments = res
      } else {
        this.pushAssessments(res.paginatedData)
      }
    } catch (e: any) {
      this.assessments = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async listUserAssessments(
    userId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const assessmentService = new AssessmentService()
      const res: Pagination<Array<Assessment>> = await assessmentService.listUserAssessments(
        userId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )

      if (res && page === 1) {
        this.userAssessments = res
      } else {
        this.pushUserAssessments(res.paginatedData)
      }
    } catch (e: any) {
      this.userAssessments = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async getAssessmentData(id: string, filterUserId?: string): Promise<void> {
    try {
      const assessmentService = new AssessmentService()

      const res: Assessment = await assessmentService.getById(id)

      if (filterUserId) {
        this.assessmentUserData = res.users.filter((assessmentUser) => {
          return assessmentUser.user._id === filterUserId
        })[0]
      }

      this.assessment = res
    } catch (e: any) {
      this.assessment = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async getAssessmentUserAnswers(assessment: string, user: string): Promise<void> {
    try {
      const assessmentService = new AssessmentService()
      const res: AssessmentEvaluationForm = await assessmentService.getAssessmentUserAnswers(assessment, user)

      if (res) {
        this.assessmentEvaluationForm = new AssessmentEvaluationForm(res.assessment, res.user)
        this.assessmentEvaluationForm = {
          ...this.assessmentEvaluationForm,
          currentCompetence: res.currentCompetence,
          currentProgress: res.currentProgress,
          currentCompetenceManager: res.currentCompetenceManager,
          currentProgressManager: res.currentProgressManager,
          answers: res.answers,
          answersManager: res.answersManager,
        }
      }
    } catch (e: any) {}
  }

  async deleteAssessment(id: string): Promise<Assessment | undefined> {
    try {
      const assessmentService = new AssessmentService()
      const deleted = await assessmentService.deleteAssessment(id)

      this.assessmentsListPage = 1
      this.listAssessmentsByTenant(
        this.authStore.tokenData!.tenant,
        this.assessmentsListPage,
        parseInt(process.env.REACT_APP_DATA_LIMIT as string),
        'createdAt',
        'desc',
        ''
      )

      return deleted
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  getStepLabel(step: AssessmentSteps) {
    let translatedStep = ''
    if (step) {
      switch (step) {
        case 'self_evaluation':
          translatedStep = this.i18nStore.dictionary.selfEvaluation
          break
        case 'managers_evaluation':
          translatedStep = this.i18nStore.dictionary.managerEvaluation
          break
        case 'evaluation_completed':
          translatedStep = this.i18nStore.dictionary.evaluationDone
          break
      }
    }
    return translatedStep
  }

  get assessmentsListPage() {
    return this._assessmentsListPage
  }

  set assessmentsListPage(page: number) {
    this._assessmentsListPage = page
  }

  set assessments(assessments: Pagination<Array<Assessment>> | null) {
    this._assessments = assessments
  }

  get assessments() {
    return this._assessments
  }

  set userAssessments(assessments: Pagination<Array<Assessment>> | null) {
    this._userAssessments = assessments
  }

  get userAssessments() {
    return this._userAssessments
  }

  get assessmentFormData() {
    return this._assessmentFormData
  }

  set assessmentFormData(assessment: AssessmentForm) {
    this._assessmentFormData = assessment
  }

  set assessment(assessment: Assessment | null) {
    this._assessment = assessment
  }

  get assessment() {
    return this._assessment
  }

  get assessmentFormValid() {
    return this._assessmentFormValid
  }

  set assessmentFormValid(isValid: boolean) {
    this._assessmentFormValid = isValid
  }

  set assessmentUserData(assessmentUserData: AssessmentUserData | null) {
    this._assessmentUserData = assessmentUserData
  }

  get assessmentUserData() {
    return this._assessmentUserData
  }

  get assessmentEvaluationForm() {
    return this._assessmentEvaluationForm
  }

  set assessmentEvaluationForm(assessmentEvaluationData) {
    this._assessmentEvaluationForm = assessmentEvaluationData
  }

  checkCompetenceValueValidity = (competenceIndex: number, competenceValueIndex: number, isManagerStep: boolean) => {
    if (this.assessmentEvaluationForm) {
      if (!isManagerStep) {
        let competenceValueData =
          this.assessmentEvaluationForm.answers[competenceIndex].competenceValues[competenceValueIndex]

        if (competenceValueData.reached && competenceValueData.reached !== 0) {
          this.assessment!.competencies[competenceIndex].competenceValues[competenceValueIndex].isValid = true
        }
      } else {
        let competenceValueData =
          this.assessmentEvaluationForm.answersManager[competenceIndex].competenceValues[competenceValueIndex]

        if (
          competenceValueData.reached !== 0 &&
          competenceValueData.expected !== 0 &&
          competenceValueData.priority !== 0
        ) {
          this.assessment!.competencies[competenceIndex].competenceValues[competenceValueIndex].isValid = true
        }
      }
    }
  }

  updateCompetenceValue = (
    e: any,
    field: any,
    competenceIndex: number,
    competenceValueIndex: number,
    isManagerStep: boolean
  ) => {
    if (this.assessmentEvaluationForm) {
      let competenceAnswers = isManagerStep
        ? this.assessmentEvaluationForm.answersManager[competenceIndex]
        : this.assessmentEvaluationForm.answers[competenceIndex]
      let competenceValue: any = competenceAnswers.competenceValues[competenceValueIndex]
      competenceValue[field] = e.target.value
    }
  }

  pushAssessments = (assessments: Array<Assessment>) =>
    (this._assessments!.paginatedData = [...this._assessments!.paginatedData, ...assessments])

  pushUserAssessments = (assessments: Array<Assessment>) =>
    (this._userAssessments!.paginatedData = [...this._userAssessments!.paginatedData, ...assessments])

  set assessmentFormSteps(steps: Array<{ title: string; completed?: boolean }>) {
    this._assessmentFormSteps = steps
  }

  get assessmentFormSteps() {
    return this._assessmentFormSteps
  }

  validateStep1 = () => {
    let isValid = false
    if (this.assessmentFormData) {
      isValid =
        this.assessmentFormData.name!.pt === '' ||
        this.assessmentFormData.name!.en === '' ||
        this.assessmentFormData.name!.es === '' ||
        typeof this.assessmentFormData.endDate === 'undefined'
    }
    return !isValid
  }

  setAssessmentEvaluationFormDefaultValues = () => {
    if (this.assessment && this.assessmentEvaluationForm) {
      let competenceAnswers = this.assessmentEvaluationForm.answers
      let competenceAnswersManager = this.assessmentEvaluationForm.answersManager

      this.assessment.competencies.forEach((competence: Competence, i: number) => {
        competenceAnswers.push({
          competence: competence._id,
          competenceValues: [] as any,
        })
        competenceAnswersManager.push({
          competence: competence._id,
          competenceValues: [] as any,
        })
        competence.competenceValues!.forEach((competenceValue: CompetenceValue) => {
          competenceAnswers[i].competenceValues.push({
            competenceValue: competenceValue._id as string,
            reached: 0,
            certification: {
              hasCertification: false,
              expirationDate: new Date(),
            },
          })
          competenceAnswersManager[i].competenceValues.push({
            competenceValue: competenceValue._id as string,
            reached: 0,
            expected: 0,
            priority: 0,
          })
        })
      })
      this.assessmentEvaluationForm = {
        ...this.assessmentEvaluationForm,
        answers: competenceAnswers,
        answersManager: competenceAnswersManager,
      }
    }
  }

  validateStep2 = () => this.assessmentFormData.competencies!.length > 0
  validateStep3 = () => this.assessmentFormData.users!.length > 0

  validateStep = (step: number) => {
    const step1Valid = this.validateStep1()
    const step2Valid = this.validateStep2()
    const step3Valid = this.validateStep3()

    if (step === 0) {
      this.handleStepCompleted(0, step1Valid)
    }

    if (step === 1) {
      this.handleStepCompleted(1, step2Valid)
    }

    if (step === 2) {
      this.handleStepCompleted(2, step3Valid)
    }

    if (step1Valid && step2Valid && step3Valid) {
      this.assessmentFormValid = true
    } else {
      this.assessmentFormValid = false
    }
  }

  handleStepCompleted = (step: number, completed: boolean) => (this.assessmentFormSteps[step].completed = completed)
}
