import { Grid, Typography, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, Fragment } from 'react'
import { StoreContext } from '../../../stores/store.context'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate } from 'react-router-dom'

interface AssessmentEvaluateUserSuccessProps {
  step?: string | null
  view?: string | null
}

const AssessmentEvaluateUserSuccess: React.FC<AssessmentEvaluateUserSuccessProps> = (
  props: AssessmentEvaluateUserSuccessProps
) => {
  const { i18nStore, assessmentStore } = useContext(StoreContext)
  const navigate = useNavigate()

  const toggleSuccessStep = (): boolean => {
    if (assessmentStore.assessmentEvaluationForm) {
      if (props.step && props.step === 'managers_evaluation') {
        return assessmentStore.assessmentEvaluationForm.currentProgressManager === 100
      }
      return assessmentStore.assessmentEvaluationForm.currentProgress === 100
    }

    return false
  }

  return (
    <Fragment>
      {assessmentStore.assessmentEvaluationForm && toggleSuccessStep() && (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12} textAlign="center">
            <CheckCircleIcon color="success" sx={{ fontSize: '5rem' }} />
            <Typography variant="h2" sx={{ mt: 3 }}>
              {props.step === 'managers_evaluation' || (props.step === 'evaluation_completed' && props.view)
                ? i18nStore.dictionary.managerEvaluationDone
                : i18nStore.dictionary.selfEvaluationDone}
            </Typography>
            <Typography variant="h5" sx={{ mt: 3, maxWidth: '500px', marginX: 'auto' }}>
              {props.step === 'managers_evaluation' || (props.step === 'evaluation_completed' && props.view)
                ? i18nStore.dictionary.managerEvaluationDoneMessage
                : i18nStore.dictionary.selfEvaluationDoneMessage}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }} textAlign="center">
            {
              <Button
                variant="text"
                onClick={() =>
                  props.step === 'managers_evaluation' || (props.step === 'evaluation_completed' && props.view)
                    ? navigate(`/secure/assessment/${assessmentStore.assessment?._id}/summary`)
                    : navigate(`/secure/assessment/`)
                }
              >
                {i18nStore.dictionary.backAssessments}
              </Button>
            }
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

export default observer(AssessmentEvaluateUserSuccess)
