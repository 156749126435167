import { observer } from 'mobx-react-lite'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { StoreContext } from '../stores/store.context'
import { useContext } from 'react'

const ThemeSwitcher = () => {
  const { themeStore } = useContext(StoreContext)

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button onClick={() => themeStore.setThemeMode('light')}>
          <LightModeOutlinedIcon sx={{ color: 'secondary.lighter' }} />
        </Button>
        <Button
          onClick={() => {
            themeStore.setThemeMode('dark')
          }}
        >
          <DarkModeOutlinedIcon sx={{ color: 'secondary.lighter' }} />
        </Button>
      </Stack>
    </>
  )
}

export default observer(ThemeSwitcher)
