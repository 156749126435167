import { Box, Container, Grid, Typography } from '@mui/material'

const Footer: React.FC = () => {
  return (
    <Box sx={{ mt: 10, width: '100%' }}>
      <Box sx={{ backgroundColor: 'primary.main', py: 4 }}>
        <Container maxWidth="md">
          <Grid
            container
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            alignItems="center"
          ></Grid>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: 'secondary.main', py: 1 }}>
        <Container maxWidth="md">
          <Grid container justifyContent="end" alignItems="center">
            <Grid item sx={{ display: 'flex' }}>
              <Typography variant="body2" color="primary.contrastText">
                Política de privacidade
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
