import { Grid, Typography, Button, Divider, List, ListItem, ListItemText, IconButton, Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect } from 'react'
import { StoreContext } from '../../../stores/store.context'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { ModalOpts } from '../../../models/modal.model'
import CompetencesListModal from '../../manage-competencies/modals/competences-list.modal'
import { Competence } from '../../../models/competence.model'
import DeleteIcon from '@mui/icons-material/Delete'

interface AssessmentFormStep2Props {
  step: number
}

const AssessmentFormStep2: React.FC<AssessmentFormStep2Props> = (props: AssessmentFormStep2Props) => {
  const { i18nStore, modalStore, assessmentStore } = useContext(StoreContext)

  useEffect(() => {}, [assessmentStore.assessmentFormData])

  const onSelectCompetences = (competencies: Array<Competence>) => {
    assessmentStore.assessmentFormData = {
      ...assessmentStore.assessmentFormData,
      competencies: competencies,
    }

    assessmentStore.validateStep(props.step)
  }

  const handleRemoveCompetence = (competence: Competence, index: number) => {
    assessmentStore.assessmentFormData = {
      ...assessmentStore.assessmentFormData,
      competencies: assessmentStore.assessmentFormData.competencies!.filter((obj) => competence._id !== obj._id),
    }
    assessmentStore.validateStep(props.step)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="subtitle2">{i18nStore.dictionary.informCompetences}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              const modalOpts: ModalOpts = {
                size: 'sm',
              }
              modalStore.openModal(<CompetencesListModal emitSelected={onSelectCompetences} />, modalOpts)
            }}
          >
            <AddCircleIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {i18nStore.dictionary.addCompetence}
            </Typography>
          </Button>

          <Typography variant="body2">
            {i18nStore.dictionary.totalCompetencies}: {assessmentStore.assessmentFormData.competencies!.length}
          </Typography>
        </Box>

        <Divider sx={{ mt: 2 }} />
      </Grid>

      <Grid item xs={12}>
        <List sx={{ width: '100%', paddingX: 2 }}>
          {assessmentStore.assessmentFormData.competencies!.map((competence, index) => {
            return (
              <Fragment key={index}>
                <ListItem
                  disablePadding
                  sx={{ '&:not(:first-of-type)': { mt: 2 } }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveCompetence(competence, index)}
                    >
                      <DeleteIcon sx={{ color: 'text.primary' }} />
                    </IconButton>
                  }
                >
                  <ListItemText primary={competence.name[i18nStore.language]} />
                </ListItem>
                <Divider component="li" />
              </Fragment>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

export default observer(AssessmentFormStep2)
